:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --midnightBlue: #002366;
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
  --red: #dd3139;
  --grey: #767676;
}

.event-about-div-bs {
  /* margin-top: 64px; */
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--white);
  border: 1px solid var(--lightGrey);
  border-radius: 8px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 24px;
  padding-right: 24px;
  /* padding-left: 16px;
  padding-right: 16px; */
  width: 100%;
}

.event-description-input-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--lightGrey);
  border-radius: 4px;
  height: 124px;
  align-items: "center";
}

.event-description-top-aligned-input {
  font-family: "Poppins Regular 400", sans-serif;
  font-size: 16px;
  color: var(--black);
}

.banner-container-overview {
  display: flex;
  height: 245px;
  width: 245px;
  flex-direction: column;
  margin-bottom: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--lightGrey);
  border-radius: 4px;
  align-items: "center";
  background-color: var(--veryLightGrey);
  padding-top: 25px;
  padding-bottom: 25px;
  align-items: center;
  justify-content: center;
}

.banner-container-img-bs {
  display: flex;
  height: 245px;
  width: 245px;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: var(--lightGrey);
  border-radius: 4px;
  align-items: "center";
  background-color: var(--veryLightGrey);
  align-items: center;
  position: relative;
}