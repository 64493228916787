:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --midnightBlue: #002366;
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
}

.page-wrapper-event {
  position: relative;
  min-height: 100vh;
  content: "";
  background-color: #fbfbfb;
  width: 100%;
  /* height: 76%; */
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  background-position: bottom;
  overflow-x: hidden;
  overflow-y: hidden;
}

.add-on-empty-container {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--lightGrey);
  background: var(--white);
  margin: 40px 28px;
  border-radius: 8px;
}

.crop-banner-for-square .reactEasyCrop_Container {
  background-color: #f1f1f1;
  width: 393.75px;
  margin: 0 auto;
}

.event-header-sideNav {
  display: flex;
  flex-direction: row;
  width: 18%;
  /* justify-content:center */
}

.event-content-div {
  display: flex;
  flex-direction: column;
  padding-bottom: 46px;
}

.event-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.event-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 32px;
}

.event-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.event-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 32px;
}

.account-verify-setup-container
{
  padding-top: 24px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .event-header-sideNav {
    width: 30%;
  }
}

@media (max-width: 767px) {
  .event-header-sideNav {
    width: 100%;
  }
}


.page-wrapper-event .rht-body-inner-content .event-details-top-innerMenu .generic-common-row-div.event-details-topMneu-rht {
  padding-right: 24px;
  gap: 24px;
}

.page-wrapper-event .rht-body-inner-content .event-details-top-innerMenu .generic-common-row-div.event-details-topMneu-rht .Box1 {
  margin-right: 0 !important;
}

.page-wrapper-event .rht-body-inner-content .event-details-event-scroll-container .event-detailsTop-btns-inner {
  max-width: 100%;
  overflow: auto;
  flex-wrap: nowrap;
  padding-bottom: 10px;
}

.page-wrapper-event .rht-body-inner-content .event-details-event-scroll-container .event-detailsTop-btns-inner .create-event-btnCustom {
  height: 40px;
  margin-bottom: 0;
}

.event-details-topMneu-rht .Box1>span {
  white-space: nowrap;
}

.page-wrapper-event .rht-body-inner-content .BusinessSignUp-inner-createBlock {
  height: 48px !important;
  width: 48px !important;
  cursor: pointer;
}

.page-wrapper-event .rht-body-inner-content .BusinessSignUp-inner-createBlock .hover-bg-button {
  /* height: 48px !important;
  width: 48px !important;
  padding: 12px !important;
  border-radius: 50% !important; */
}

.page-wrapper-event .rht-body-inner-content .BusinessSignUp-inner-createBlock .hover-bg-button svg {
  /* height: 24px!important;
  width: 24px!important;*/
}

.page-wrapper-event .rht-body-inner-content .TextCreateEntrance {
  width: 100%;
}

.page-wrapper-event .rht-body-inner-content .TextCreateEntrance>span {
  display: block;
  font-size: 16px !important;
}

/* css start 30-07-24 */

  .avhguyf.event-details-top-innerMenu .generic-common-row-div{width:50%;}

/* css end 30-07-24 */

@media (min-width:768px) and (max-width:1024px) {
  .page-wrapper-event .rht-body-inner-content .event-about-div .abcd-div .file-drop .file-drop-target .banner-upload-block {
    justify-content: center;
    display: flex !important;
  }
  .avhguyf.event-details-top-innerMenu .generic-common-row-div {
    width: 100%;
    padding: 35px 25px;
    justify-content:flex-start;
    }
}

@media screen and (max-width:767px) {
  .page-wrapper-event .rht-body-inner-content .event-details-top-innerMenu .generic-common-row-div-top.event-details-topMneu-lft {
    padding-top: 40px;
  }

  .page-wrapper-event .rht-body-inner-content .event-details-top-innerMenu .generic-common-row-div.event-details-topMneu-rht {
    padding-right: 0;
  }

  .page-wrapper-event .rht-body-inner-content .event-details-top-after-menulink-container {
    padding-top: 24px !important;
  }

  .page-wrapper-event .rht-body-inner-content .event-details-top-after-menulink-container>span.dgdwgd {
    margin-top: 16px !important;
    font-size: 16px !important;
  }

  .page-wrapper-event .rht-body-inner-content .event-about-div {
    margin: 24px 0 0 0;
    padding: 40px 16px;
    border-radius: 0;
  }

  /* .page-wrapper-event .rht-body-inner-content .event-about-div .abcd-div .file-drop .file-drop-target .banner-upload-block .BusinessSignUp-inner-createBlock {
    height: unset!important;
  } */
  .page-wrapper-event .rht-body-inner-content .timing-about-div {
    margin: 40px 0 0 0;
    padding: 40px 16px;
    border-radius: 0;
  }

  .page-wrapper-event .rht-body-inner-content .timing-about-div {}

  .page-wrapper-event .rht-body-inner-content .createEvnSD-btn-area {
    margin:31px 0 0 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    row-gap:5px;
  }

  .page-wrapper-event .rht-body-inner-content .createEvnSD-btn-area .Box1,
  .page-wrapper-event .rht-body-inner-content .createEvnSD-btn-area #box1.Box1 {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 60% !important;
  }

  .page-wrapper-event .rht-body-inner-content .EntrancesCont {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .page-wrapper-event .rht-body-inner-content .EntrancesCont .event-about-div-bs {
    border-radius: 0;
  }

  .page-wrapper-event .rht-body-inner-content .socialLinks-BusinessSignUp-inner-lft>div {
    width: 100% !important;
    padding-right: 0 !important;
  }

  .page-wrapper-event .rht-body-inner-content .EntranceBtnCont #box1,
  .page-wrapper-event .rht-body-inner-content .EntranceBtnCont .Box1 {
    margin-left: 0 !important;
    width: 100% !important;
  }

  .SocialLinkFields {
    flex-direction: column !important;
  }

  .SocialLinkFields .CommonFormGroup {
    width: 100% !important;
    padding-right: 0 !important;
  }

  .SocialLinkFields .CommonFormGroup .containerInput {
    margin: 0 !important;
  }

  .SocialLinkFields .socialLinks-BusinessSignUp-delete {
    margin-top: -102px !important;
    
  }

  .avhguyf.event-details-top-innerMenu .generic-common-row-div{justify-content:flex-end; width:100%; column-gap:3%;}
  .avhguyf.event-details-top-innerMenu .generic-common-row-div .Box1{margin-right:0px !important;}

}

@media screen and (max-width:599px) {

  .page-wrapper-event .rht-body-inner-content .createEvnSD-btn-area .Box1,
  .page-wrapper-event .rht-body-inner-content .createEvnSD-btn-area #box1.Box1 {
    width: 100% !important;
  }
}