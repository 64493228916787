:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
  --midGrey: #bebebe;
  --veryDarkBlue: #063279;
  --lightStateBlue: #f6f9fe;
  --grey: #767676;
  --red: #dd3139;
  --black: #000000;
  --hoverGreen: #e7f3ec;
  --iceWhite: #fff;
}
.menu-link-row-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  /* align-items: center; */
  /* background-color: var(--iceWhite); */
  cursor: pointer;
}
