.accordionContainer .collapse {
  visibility: visible;
}

.accordion-button::after {
  background-image: url("../../../assets/svgs/ChevronUp.svg") !important;
}

.accordion-button.collapsed::after {
  background-image: url("../../../assets/svgs/ChevronDownAccordion.svg") !important;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.accordion-button:focus-visible {
  outline: none !important;
}

.accordion {
  --bs-accordion-bg: transparent !important;
  --bs-accordion-border-color: transparent !important;
  border-bottom: 1px solid var(--lightGrey);
}
.accordion-button:focus {
  box-shadow: none !important;
}
