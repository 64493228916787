:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
  --midGrey: #bebebe;
  --veryDarkBlue: #063279;
  --lightStateBlue: #f6f9fe;
  --grey: #767676;
  --red: #dd3139;
  --black: #000000;
}
.containerInput {
  display: flex;
  flex-direction: row;
  border-width: 1px;
  border-color: red;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 1px;
  height: 48px;
  align-items: center;
  border-style: solid;
}

.iconContainerInput {
  margin-right: 6px;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.inputFieldPrimary {
  display: flex;
  text-align: left;
  width: 100%;
  height: 20px;
  color: var(--midnightBlue);
  border: 0 !important;
  border-width: 0px;
  box-shadow: inherit;
  background-color: transparent;
  padding-left:4px;
  margin-left:4px;
}
.inputFieldPrimary:focus,
.inputFieldPrimary:focus-visible {
  border: 0 !important;
  border-width: 0px;
  box-shadow: inherit;
  outline: none;
}



@media(max-width:767px){

  .iconContainerInput{margin-right:6px;}

}
