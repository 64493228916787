:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --lightGrey: #ebebeb;
}

.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: linear-gradient(180deg, var(--midBlue) 0%, var(--midBlue) 100%);
}

.loader-logo {
  position: relative;
}

.loader-logo img {
  max-width: 130px;
}

.loader-logo:after {
  width: 185px;
  height: 185px;
  content: "";
  background-image: url("../../assets/animation_4.gif");
  background-size: 100% 100%;
  position: absolute;
  top: 110%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.page-wrapper-login {
  position: relative;
  /* overflow: hidden; */
  min-height: 100vh;
  content: "";
  background: linear-gradient(300deg,
      rgba(204, 12, 207, 0.08) 0%,
      #fff 40%,
      rgba(45, 114, 226, 0.16) 100%);
  /* background-color: #FFF; */
  /* background-image: url("../../assets/png/BGImage.jpg"); */
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.login-box-container-login {
  /* justify-content: space-between; */
  display: flex;
  flex-direction: column;
  width: 513px;
  /* height: 558px;  */
  /* background-color: var(--white); */
  /* margin-left: 80px;*/
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  /* border-radius: 8px;
border-color: var(--lightGrey) !important;
border-width: 1px !important;
box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05); */
  position: relative;
}

.innerForm-account {
  width: 423px;
}

.login-inner-box-container-login {
  align-items: center;
  display: flex;
  flex-direction: column;
  /* height: 80%; */
}

.login-remember-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
}

.login-sign-in-up-container {
  align-self: center;
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
}



/* css start 10-01-24 */

.topLogin-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 0 0;
}

.topLogin-text span {
  text-align: center !important;
  display: block;
  margin: 0px !important;
}

/* .login-inner-box-container-login label{
  display:block;
  width:100%;
} */

.bottomLogin-prg-text {
  margin: 32px 0 16px;
}

.login-inner-box-container-login label {
  display: block;
  width: 100%;
  margin: 24px 0 4px;
}

.bottomLogin-prg-text span {
  font-size: 20px !important;
  line-height: 28px !important;
}

.login-remember-container {
  align-items: center;
}

.dontAccount {
  margin: 23px 0 0;
}

.bottomLogin-btn .Box1 {
  width: 360px !important;
}


@media (max-width:767.98px) {

  .login-box-container-login {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 48px;
  }

  .topLogin-tittle-text span {
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .bottomLogin-prg-text span {
    font-size: 20px !important;
    line-height: 28px !important;
  }


  .page-wrapper-login {
    background: linear-gradient(300deg,
        rgba(204, 12, 207, 0.08) 0%,
        #fff 30%,
        rgba(45, 114, 226, 0.10) 100%);
  }

  .login-inner-box-container-login .containerInput {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .forgotten-password-text span {
    margin: 0px !important;
  }

  .login-remember-container .generic-common-row-div {
    width: 40%;
  }

  .login-remember-container .forgotten-password-text {
    width: 60%;
    text-align: right;
  }

  .bottomLogin-btn {
    width: 100% !important;
  }

  .bottomLogin-btn .Box1 {
    width: 100% !important;
    margin: 0 0 20px !important;
  }



  .innerForm-account {
    width: 96%;
  }

  .dontAccount {
    margin: 8px 0 0;
    display: flex;
    flex-direction: row;
  }

  .login-remember-container .generic-common-row-div {
    flex-wrap: nowrap;
  }

  .bottomLogin-btn div#box1:first-child {
    margin-left: 0px !important;
  }

}


/* css end 10-01-24 */