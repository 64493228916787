.account-not-verify-container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--lightGrey);
  background: var(--hoverFadeBlue);
  margin-top: 32px;
  width: 100%;
}

.account-verified-container {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  justify-content: start;
  border-radius: 8px;
  border: 1px solid var(--lightGrey);
  background-color: var(--white);
  margin-top: 48px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08);
}

@media screen and (max-width:1179px) {

  .attendee-details-parent-content{flex-wrap:wrap;}
  .attendee-details-parent-content .rightBtnCont{width:100%; margin:32px 0 0;}
  .order-content-div.listing-top-headerPagination .attendee-details-scroll-container{margin:0 48px !important;}

  .page-wrapper-attendee-details .attendee-details-parent-content .rightCont{white-space:nowrap; overflow:hidden; text-overflow:ellipsis; display:block !important;}

}


@media screen and (max-width: 991px) {

    .account-verified-container .personal-details-block {
      flex-wrap: wrap !important;
    }
    .personal-details-lft {
        width: 100% !important;
    }
    .personal-details-rht {
      width: 100% !important;
      padding: 10px 0 0;
    }
    .personal-details-lft span, .personal-details-rht span {
      margin: 0 0 15px !important;
    }
    .businessDetails-block {
      flex-wrap: wrap !important;
    }
    .businessDetails-lft {
      width: 100% !important;
    }
    .businessDetails-rht {
      width: 100% !important;
      padding: 19px 0 0 !important;
  }
}

@media screen and (max-width: 767px) {

    .account-verified-container .personal-details-block{flex-wrap:wrap !important;}
    .personal-details-lft{width:100% !important;}   
    .personal-details-rht{width:100% !important; padding:10px 0 0;}
    .personal-details-lft span, .personal-details-rht span{margin:0 0 15px !important;}

    .businessDetails-block{flex-wrap:wrap !important;}
    .businessDetails-lft{width:100% !important;}
    .businessDetails-rht{width:100% !important; padding:19px 0 0 !important;}

    .businessDetails-lft .generic-common-column-div span{margin:0 0 15px !important;}
    .order-content-div.listing-top-headerPagination .attendee-details-scroll-container {
      margin: 0 0 0 17px !important;
    }
}