:root {
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
  --grey: #767676;
  --black: #000000;
}
.form-select:focus {
  box-shadow: none !important;
  border-color: var(--lightGrey) !important;
}
.form-select {
  --bs-form-select-bg-img: url("../../../assets/svgs/ChevronDown.svg");
  height: 48px;
  /* -webkit-fill-available !important;  */
  border-radius: 4px;
}
.form-select:disabled {
  background-color: var(--veryLightGrey) !important;
  opacity: 1 !important;
  border: solid 1px var(--lightGrey) !important;
}

.dropdown-toggle::after {
  display: none;
}
