
.booking-fees-progress-container {
    display: flex;
    padding: 40px 24px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid var(--lightGrey);
    background: var(--hoverFadeBlue);
    padding-bottom: 70px;
    /* margin-top: 16px; */
  }

  .rc-slider-mark-text {
    margin-top: 15px;
    font-size: 16px;
  font-weight: 600;
  font: Poppins-Medium;
  color: var(--midBlue);
  line-height: 24px;
  text-decoration: none;
  }