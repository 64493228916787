:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
}
.go-to-login-verify-email {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  background-color: var(--surfaceLight);
  margin-top: 128px;
  margin-bottom: 32px;
  border-radius: 24px;
}

.crop-banner-for-square .reactEasyCrop_Container {
  background-color: #f1f1f1;
  width: 393.75px;
  margin: 0 auto;
}



.business-signup-page-wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  content: "";
  background-color: var(--veryLightGrey);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  /* position: absolute; */
  top: 0px;
  left: 0px;
  background-position: bottom;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 100px;
  align-items: center;
  width: 100%;
}

.business-signup-inner-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
}

/* start css 15-01-2024 */

.socialLinks-blockBusinessSignUp,
.about-blockBusinessSignUp {
  width: 100%;
}

.socialLinks-BusinessSignUp-inner {
  display: flex;
  flex-wrap: wrap;
}

.socialLinks-BusinessSignUp-inner-lft {
  width: 50%;
  padding: 0 2% 0 0;
}

.socialLinks-BusinessSignUp-inner-rht {
  width: 50%;
  padding: 0 0 0 2%;
}

.socialLinks-BusinessSignUp-delete {
  justify-content: flex-end !important;
}

.socialLinks-BusinessSignUp-delete-inner {
  display: flex;
}

.socialLinks-item-BusinessSignUp {
  /* border-bottom: 1px solid #ebebeb;
  padding: 0 0 32px; */
}

.socialLinks-item-BusinessSignUp:nth-last-child(2) {
  border-bottom: none;
  padding: 0px;
}

.business-signup-inner-container .tittle {
  padding: 19px 0 0;
  text-align: center;
}

.business-signup-inner-container .tittle span {
  font-size: 40px !important;
  font-weight: 600 !important;
}

.business-signup-inner-container .subTittle {
  max-width: 692px;
  text-align: center;
  padding: 22px 0 0;
}

.business-signup-inner-container .subTittle span {
  font-size: 20px !important;
  font-weight: 400 !important;
}

.saveCont-btn-BusinessSignUp {
  width: 100% !important;
}

.saveCont-btn-BusinessSignUp #box1,
.saveCont-btn-BusinessSignUp .Box1 {
  margin: 64px auto !important;
}

/* css start 17-04-24 */
  .about-blockBusinessSignUp-container{max-width:1050px; margin:0 auto 40px;}
  .socialLinks-blockBusinessSignUp-container{max-width:1050px; margin:0 auto}
  .about-blockBusinessSignUp-container .event-about-div-bs, .socialLinks-blockBusinessSignUp-container .event-about-div-bs{
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.03);
  }
    
/* css end 17-04-24 */

@media (max-width: 767.98px) {
  .business-signup-page-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }

  .business-signup-inner-container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .about-blockBusinessSignUp-innerContent {
    flex-wrap: wrap;
    flex-direction: column !important;
  }

  .business-signup-inner-container .tittle span {
    font-size: 32px !important;
  }

  .business-signup-inner-container .subTittle {
    max-width: 358px;
    padding: 24px 0 0;
  }

  .businessSignUp-top-bg {
    width:100%;
    height:100px;
  }

  .businessSignUp-top-bg2 {
    width: 95%;
  }

  .businessSignUp-top-bg3 {
    width: 95%;
  }

  .businessSignUp-top-plusIcon {
    margin: -66px 0 0;
  }

  .businessSignUp-top-plusIcon .hover-bg-button {
    width: 100% !important;
    height: 100% !important;
  }

  .businessSignUp-top-plusIcon .hover-bg-button svg {
    width: 45px;
    height: 45px;
  }

  .businessSignUp-top-plusIcon .BusinessSignUp-inner-createBlock {
    width: 96px !important;
    height: 96px !important;
  }

  .about-blockBusinessSignUp .event-about-div-bs {
    border-radius: 0px;
  }

  .socialLinks-blockBusinessSignUp .event-about-div-bs {
    margin-top:0px !important;
    border-radius: 0px;
  }

  .saveCont-btn-BusinessSignUp {
    padding: 0 16px;
  }

  .saveCont-btn-BusinessSignUp #box1,
  .saveCont-btn-BusinessSignUp .Box1 {
    margin: 48px auto 64px !important;
    width: 100% !important;
  }

  .saveCont-btn-BusinessSignUp #box1 span,
  .saveCont-btn-BusinessSignUp .Box1 span {
    font-size: 16px !important;
  }

  .socialLinks-BusinessSignUp-inner .socialLinks-BusinessSignUp-inner-lft{width:100%; margin:0px; padding:20px 0 0;}
  .socialLinks-BusinessSignUp-inner .socialLinks-BusinessSignUp-inner-rht{width:100%; margin:0px; padding:20px 0 0; margin-top: 16px;}
  .socialLinks-BusinessSignUp-inner .socialLinks-BusinessSignUp-inner-lft span, .socialLinks-BusinessSignUp-inner .socialLinks-BusinessSignUp-inner-rht span{margin:0 0 6px !important;}
  .socialLinks-BusinessSignUp-inner .containerInput{margin:0px;}

}

/* end css 15-01-2024 */