.logo-and-supporting-text {
  padding-right: 5%;
  flex-direction: column;
  align-items: flex-start;
}

.footer-links .badgefalse-colorgray-theme div {
  font-family: var(--body-xl) !important;
  text-transform: capitalize !important;
  font-size: var(--gap-base) !important;
  font-weight: var(--font-w-400) !important;
}

.footer-links,
.logo-and-links,
.logo-and-supporting-text {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}

body .footer-d {
  
  /* padding: 50px var(--padding-base) 40px; */
}

.footer-d .content5 {
  align-items: flex-start;
}

.footer-links {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
}

.footer-links-section {
  display: flex;
  justify-content: center;
  width: var(--full-width);
}

.footer-links-section a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.logo-and-links {
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  padding-right: 5%;
}

.footer-links-section h4 {
  font-weight: var(--font-w-500);
  font-size: var(--display-xs-size);
  margin: 0 0 8px;
}

.footer-links-section text {
  display: block;
  margin: 16px 0 0;
  font-size: 16px;
  color: var(--midnightBlue);
}

.footer-links-section a {
  color: var(--midnightBlue);
}

.heading4 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
}

.mobile-app-store-badge,
.mobile-app-store-badge1 {
  position: relative;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}

.mobile-app-store-badge {
  align-self: stretch;
  border-radius: var(--br-6xs);
  max-width: var(--full-width);
}

.mobile-app-store-badge1 {
  border-radius: var(--br-8xs);
  width: 135px;
}

.actions,
.app-store-actions {
  flex-direction: column;
  gap: var(--gap-base);
}

.actions {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.app-store-actions {
  font-size: var(--body-xs-size);
  color: var(--monochrome-white);
}

.app-store-actions,
.container1,
.content4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.content4 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-13xl);
}

.container1 {
  width: var(--full-width);
  max-width: var(--full-width);
  flex-direction: column;
  padding: 0 3%;
  box-sizing: border-box;
}

.footer-text {
  flex: 1;
  position: relative;
  line-height: var(--gap-5xl);
  max-width: 80%;
}

.footer-text-privacy-policy {
  font-size: var(--padding-15x);
}

.icons-24x-white {
  position: relative;
  width: var(--gap-5xl);
  height: var(--gap-5xl);
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}

.social-icons {
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xl);
}

.footer-d .social-icons img {
  margin-left: 8px;
}

.container2,
.content5,
.footer-d,
.social-icons {
  display: flex;
  justify-content: flex-start;
}

.content5 {
  align-self: stretch;
  border-top: 1px solid var(--monochrome-grey);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-13xl) 0 0;
  gap: var(--gap-13xl);
  justify-content: space-between;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--full-width);
}

.container2,
.footer-d {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 var(--padding-13xl);
  color: var(--midnightBlue);
}

.footer-d {
  background: var(--twotixx-white);
  box-sizing: border-box;
  color: var(--midnightBlue);
  font-family: var(--body-xl);
  font-size: var(--body-md-size);
  align-items: center;
  /* gap: var(--gap-45xl); */
  gap:22px;
  padding: var(--padding-45xl) var(--padding-72);
  text-align: left;
  margin-left: var(--width-sidebar);
  width: var(--width-without-sidebar);
}

.footer-d .container1 {
  padding: 0;
}

.iconfooterLogo {
  position: relative;
  width: var(--padding-29xl);
  height: var(--padding-29xl);
  overflow: hidden;
  flex-shrink: 0;
}

.footerLogo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

/* css start 12-01-24 */

.footer-d .app-store-actions .actions .Box1 {
  margin-top: 0px !important;
  width: auto !important;
}

.footer-d .app-store-actions .actions .Box1 span {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.footer-d .app-store-actions .actions .Box1 svg {
  width: 22px !important;
  height: 22px !important;
}

/* css end 12-01-24 */

/* css start 07-11-24 */

.footer-topInner-link {
  padding: 0 0 29px !important;
}

/* css end 07-11-24 */


/* Responsive styles */

@media screen and (max-width: 1024px) {
  /* .footer-d .content4 {
    flex-direction: column;
  }

  .footer-d .app-store-actions .actions .Box1,
  .footer-d .app-store-actions .actions #box1 {
    width: auto !important;
    margin: 0px !important;
  }
  .footer-d .app-store-actions .actions .Box1 span,
  .footer-d .app-store-actions .actions #box1 span {
    font-size: 16px !important;
  }

  .footer-links-section .footer-links-company,
  .footer-links-section .footer-links-software,
  .footer-links-section .footer-links-contact {
    padding: 0 12px 0 0;
  } */
}

@media screen and (max-width: 1199px) {

  .page-wrapper-fp .footer-d,
  .page-wrapper-login .footer-d {
    margin-left: 0 !important;
    width: 100% !important;
  }

  .footer-d {
    margin-left: var(--width-sidebar) !important;
    padding: var(--padding-45xl) var(--padding-5xl);
    width: calc(100% - var(--width-sidebar)) !important;
  }

  .footer-d .content4 {
    flex-direction: column;
    gap: 40px;
  }

  .footer-d .logo-and-links {
    padding-right: 0;
    gap: 40px;
    width: 100%;
    flex-direction: column;
  }

  .footer-d .logo-and-links .logo-and-supporting-text {
    width: 64px;
  }

  .footer-d .logo-and-links .footer-links-section {
    flex-direction: row;
    gap: 40px;
    width: 100%;
    flex-wrap: wrap;
  }

  .footer-d .logo-and-links .footer-links-section .linksCont {
    flex: 1 0 0;
  }
}

@media screen and (max-width: 767px) {
  .footer-d {
    margin-left: 0 !important;
    padding: 40px 16px;
    width: 100% !important;
  }

  .logo-and-links {
    flex-direction: column;
    padding-right: 0;
    width: 100%;
  }

  .footer-d .logo-and-links .footer-links-section {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px 16px;
    width: 100%;
  }

  .footer-links-section .footer-links-company,
  .footer-links-section .footer-links-software {
    flex: 1 0 0 !important;
  }

  .footer-links-section .footer-links-contact {
    flex: 100% 0 0 !important;
  }

  .footer-links-section .footer-links-company,
  .footer-links-section .footer-links-software,
  .footer-links-section .footer-links-contact {
    /* width: 50%; */
  }

  .footer-links-section .footer-links-contact {
    /* margin: 40px 0 0; */
  }

  .footer-d .app-store-actions {
    /* margin-top: 40px; */
  }

  .footer-links-section {
    /* flex-wrap: wrap; */
  }

  body .footer-d .app-store-actions {
    /* padding: 0 0 50px; */
  }

  body .footer-d .app-store-actions .actions {
    align-items: flex-start;
    flex-direction: column;
  }

  .footer-bottomBar-link{
    padding:22px 0 0;
    align-self:flex-start !important;
  }

}

@media screen and (max-width: 768px) {

  .container1,
  .footer-d {
    /* width: var(--full-width); */
    /* padding: 0 var(--padding-base); */
    /* padding: 0; */
  }

  .content4,
  .social-icons {
    flex-direction: column;
    gap: var(--gap-base);
  }

  .logo-and-supporting-text,
  .mobile-app-store-badge,
  .mobile-app-store-badge1 {
    width: 30%;
    height: auto;
  }

  .footerLogo,
  .actions,
  .app-store-actions {
    align-items: center;
    justify-content: center;
  }

  .footer-d .app-store-actions,
  .footer-d .app-store-actions .actions {
    align-items: flex-start !important;
  }

  .footer-text {
    margin-right: 0;
    max-width: var(--full-width);
  }

  .actions {
    flex-direction: column;
  }

  .content5 {
    flex-direction: column;
    gap: var(--gap-xs);
  }
}

@media screen and (max-width: 480px) {

  .heading4,
  .footer-text {
    font-size: var(--body-sm-size);
  }
}

.social-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}

@media screen and (max-width: 480px) {
  .icons-24x-white {
    width: var(--gap-xl);
    height: var(--gap-xl);
  }
}