:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --lightGrey: #ebebeb;
}

.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: linear-gradient(180deg, var(--midBlue) 0%, var(--midBlue) 100%);
}

.loader-logo {
  position: relative;
}

.loader-logo img {
  max-width: 130px;
}

.loader-logo:after {
  width: 185px;
  height: 185px;
  content: "";
  background-image: url("../../assets/animation_4.gif");
  background-size: 100% 100%;
  position: absolute;
  top: 110%;
  left: 50%;

  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.page-wrapper-fp {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  content: "";
  background: linear-gradient(
    300deg,
    rgba(204, 12, 207, 0.08) 0%,
    #fff 40%,
    rgba(45, 114, 226, 0.16) 100%
  );
  /* background-color: #FFF; */
  /* background-image: url("../../assets/png/BGImage.jpg"); */
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.login-box-container-fp {
  /* justify-content: space-between; */
  display: flex;
  flex-direction: column;
  width: 513px;
  /* height: 558px;  */
  /* background-color: var(--white); */
  /* margin-left: 80px;*/
  /* margin-top: 80px; */
  padding-top: 75px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  /* border-radius: 8px;
  border-color: var(--lightGrey) !important;
  border-width: 1px !important;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05); */
  position: relative;
}

.login-inner-box-container-fp {
  align-items: center;
  display: flex;
  flex-direction: column;
  /* height: 80%; */
}

.login-remember-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
}

.login-sign-in-up-container {
  align-self: center;
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* css start 11-01-2024 */

.login-inner-box-container-fp label {
  display: block;
  width: 100%;
  margin: 24px 0 4px;
}

.backTo-signIn {
  margin: 23px 0 0;
}

.check-yourEmail-container .hover-bg-button{
  background-color:#C0D5F6 !important;
}

.password-updated-container .hover-bg-button{
  background-color:#E7F3EC !important;
}

.check-yourEmail-container .topLogin-text{
   padding:36px 0 22px;
}

.check-yourEmail-container .login-sign-in-up-container .Box1{
  margin:0 0 40px !important;
}

.check-yourEmail-container .bottomLogin-prg-text span span{
  font-weight:500;
}

.password-updated-container .hover-bg-button svg{
  width: 40px !important;
  height: 40px !important;
}


@media (max-width:767.98px){


  .page-wrapper-fp {
    background: linear-gradient(
      300deg,
      rgba(204, 12, 207, 0.08) 0%,
      #fff 30%,
      rgba(45, 114, 226, 0.10) 100%
    );
  }

  .login-box-container-fp {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 48px;
  }

  .backTo-signIn {
    margin: 8px 0 0;
  }

  .checkEmail-resend{
    flex-direction:column !important;
  }

  .check-yourEmail-container .login-sign-in-up-container{
    width:100%;
  }

  .login-sign-in-up-container{
    margin-top:32px;
    width:100%;
  }

}

/* css end 11-01-2024 */
