:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --midnightBlue: #002366;
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
  --red: #dd3139;
  --hoverGreen: #e7f3ec;
  --hoverRed: #fceaeb;
  --green: #0d873c;
  --veryDarkGrey: #636363;
  --grey: #767676;
}

.page-wrapper-attendee {
  position: relative;
  min-height: 100vh;
  content: "";
  background-color: #fbfbfb;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  background-position: bottom;
  overflow-x: hidden;
  overflow-y: hidden;
}

.order-list-listingSearch-inner-block {
  display: flex;
  flex-direction: row;
  width: 45%;
  margin-left: 24px;

}

/* .attendee-header-sideNav {
  display: flex;
  flex-direction: row;
  width: 18%;
} */
.order-content-div {
  display: flex;
  flex-direction: column;
  /* margin-top: 24px; */
  /* margin-left: 350px;
    padding-right: 16px; */
  /* padding-bottom: 440px; */
  padding-bottom: 48px;
}

.order-list-parent-content {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 48px;
  margin-right: 48px;
  justify-content: flex-start !important;
  align-items: center;
}

.order-list-line-item-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-top: 48px;
  margin-bottom: 24px;
  margin-left: 24px;
  margin-right: 24px;
}

.order-list-line-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 1px solid #e7e7e7;
}

.attendee-event-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.attendee-list-container {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.order-list-item-parent-content {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  /* margin-bottom: 64px; */
  /* margin-left: 24px;
  margin-right: 24px; */
  margin-left: 0px;
  margin-right: 0px;
  /* height: 558px;  */
  background-color: var(--white);
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  border-color: var(--lightGrey) !important;
  border-width: 1px !important;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
  position: relative;
}

/* css start 12-01-24 */
.avhguyf {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100%;
}

/* css end 12-01-24 */
.listing-tittleParg-block .listing-parg {
  padding: 16px 0 0;
}

.listing-tittleParg-block .listing-parg span {
  font-size: 16px !important;
}

.order-list-line-item-tittleInner {
  display: flex;
  width: 100%;
}

.btnContEADmobile {
  margin-top: 32px;
  padding: 0 16px;
}

.btnContEADmobile .Box1 {
  margin: 0 !important;
  width: 100% !important;
}

.OrderListTopTitleButtonCont {}

.order-list-line-item-container .order-list-line-item-inner {
  padding: 0 10px;
}

.orderList-inner-widthScroll:last-child .order-list-line-item-container {
  border-bottom: none;
}


@media only screen and (max-width: 1199px) {
  /* .attendee-header-sideNav {
    width: 30%;
  } */
   
}

@media only screen and (max-width: 1024px) {

  .orderDetails-lineItem-data.fullname-order-details-container {
    margin-left: 48px !important;
  }

  .order-list-line-item-title-container {
    display: block;
  }

  .orderDetails-lineItem-data {
    width: 100% !important;
    margin: 24px 0 0 !important;
  }

  .order-list-parent-content.listing-tittleParg-block {
    flex-wrap: wrap;
  }

  .order-list-parent-content.listing-tittleParg-block .OrderListTopTitleButtonCont {
    width: 100%;
  }

  .order-list-parent-content.listing-tittleParg-block .btnContEAD {
    width: 100%;
    padding: 22px 0 0;
  }

  .order-list-parent-content.listing-tittleParg-block .btnContEAD .Box1 {
    margin-left: 0px !important;
  }

  .order-list-parent-content {
    margin-left: 24px;
  }

  /* .order-content-div.listing-top-headerPagination .listingSearch-block div {
    width: 100% !important;
    padding: 0 24px;
    margin: 0 !important;
  } */

  .orderHistroy-list-container {
    overflow: scroll;
  }

  .orderHistroy-list-container-inner {
    width: 800px;
  }

  .listing-form-filedStyle {
    flex-direction: column !important;
  }

  .listing-form-filedStyle .listing-form-filed-item {
    margin: 8px 0 !important;
  }

  .invoiceHistory-list-scroll {
    overflow-x: auto;
  }

  .invoiceHistory-list-scroll .invoiceHistory-list-scroll-inner {
    width: 800px;
  }

}

.orderDetails-bottomBtn-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px 24px 0px 24px;
  justify-content: space-between;
  align-items: center;
}



@media only screen and (max-width: 767px) {

  /* .attendee-header-sideNav {
    width: 100%;
  } */
  .orderDetails-lineItem-data.fullname-order-details-container {
    margin-left: 16px !important;
  }

  .order-list-listingSearch-inner-block {
  width: 100%;
  margin-left: 0px;
  }

  .orderList-inner-widthScroll {
    /* overflow-x: auto; */
  }

  .order-list-line-item-container {
    /* width: 680px; */
    padding: 8px 0px;
  }

  .listing-top-headerPagination .listingPagination-block .event-details-top-innerMenu .event-details-topMneu-lft {
    padding: 0px;
  }

  body .listing-top-headerPagination .event-details-top-innerMenu {
    padding: 16px 16px !important;
  }

  .listing-top-headerPagination .order-list-parent-content div {
    flex: 1 !important;
  }

  .listing-top-headerPagination .order-list-parent-content {
    padding: 32px 16px !important;
    margin: 0px !important;
  }

  .listing-tittleParg-block .listing-parg {
    padding: 16px 0 0;
  }

  .listing-tittleParg-block .listing-parg span {
    font-size: 16px !important;
  }

  .listing-top-headerPagination .menu-link-row-container span {
    font-size: 12px !important;
  }

  .listingSearch-block {
    padding: 0 16px !important;
  }

  /* .listingSearch-block div {
    width: 100% !important;
    margin: 0px !important;
  } */

  .order-content-div.listing-top-headerPagination .listingSearch-block div {
    padding: 0;
  }

  .listing-form-filedStyle {
    display: block !important;
    margin: 25px 0 0 !important;
    padding: 0 16px;
  }

  .listing-form-filedStyle .listing-form-filed-item {
    margin: 16px 0 0 !important;
  }

  .order-list-line-item-title-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 16px;
    padding-right: 16px;
  }

  .order-list-item-parent-content {
    margin-left: 16px;
    margin-right: 15px;
    padding: 4px 15px;
  }

  .orderDetails-lineItem-tittle-container {
    display: block;
    margin-top: 0px !important;
  }

  .orderDetails-lineItem-data {
    width: 100% !important;
    margin: 24px 0 0 !important;
  }

  .orderDetails-listTable-container {
    border-radius: 0px;
    margin: 0px !important;
    padding: 0px 16px !important;
    overflow: auto;
  }

  .orderDetails-listTable-item .listLine {
    display: none;
  }

  .orderDetails-listTable-item .order-list-line-item-container {
    padding: 0px !important;
  }

  .orderDetails-listTable-item {
    /* overflow-x:auto;
    overflow-y:hidden; */
  }

  .orderDetails-listTable-item .order-list-line-item-container {
    width: 1026px;
    border-bottom: 1px solid #ebebeb;
    padding: 22px 0 !important;
  }

  .orderDetails-listTable-item .order-list-line-item-container div {
    width: 270px !important;
  }

  .orderDetails-bottomBtn-container {
    flex-direction: column !important;
    padding: 32px 16px 0px;
  }

  .orderDetails-bottomBtn-container #box1 {
    margin: 0px 0 16px !important;
    width: 100% !important;
  }

  .orderDetails-bottomBtn-container div#box1:first-child {
    margin-left: 0px !important;
  }

  .orderDetails-listTable-item .listingList-btns-block div {
    width: auto !important;
  }

  .order-list-line-item-title-container {
    display: block;
    overflow-x: auto;
  }

  .order-list-line-item-tittleInner {
    width: 680px;
  }

  .order-list-line-item-tittleInner div:nth-child(1) {
    width: 38% !important;
  }

  .order-list-line-item-container div.order-list-line-item-inner:nth-child(1) {
    width: 27% !important;
  }

  .order-list-line-item-tittleInner div:nth-child(2) {
    width: 40% !important;
  }

  .order-list-line-item-container div.order-list-line-item-inner:nth-child(2) {
    width: 32% !important;
  }


  .order-list-line-item-tittleInner div:nth-child(3) {
    width: 30% !important;
  }

  .order-list-line-item-container div.order-list-line-item-inner:nth-child(3) {
    width: 29% !important;
  }


  .order-list-line-item-tittleInner div:nth-child(4) {}

  .order-list-line-item-container div.order-list-line-item-inner:nth-child(4) {
    width: 28% !important;
  }


  .order-list-line-item-tittleInner div:nth-child(5) {}

  .order-list-line-item-container div.order-list-line-item-inner:nth-child(5) {
    width: 36% !important;
  }


  .order-list-line-item-tittleInner div:nth-child(6),
  .order-list-line-item-container div.order-list-line-item-inner:nth-child(6) {}

  .orderHistroy-list-container {
    overflow: scroll;
    width: 95%;
    -webkit-overflow-scrolling: touch;
    /* More properties for a fixed height ... */
  }

  .orderHistroy-list-container-inner {
    width: 800px;
  }
}



@media (min-width:768px) and (max-width:991px) {
  .btnContEAD.forDesktop .Box1 {
    width: auto !important;
  }
}