/* Sidebar container */
:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --lightGrey: #ebebeb;
  --grey: #767676;
}

.mobile-menu-contentTop-lft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.home-header-sideNav {
  display: flex;
  flex-direction: row;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  /* width: 18%; */
  width: var(--width-sidebar);
  z-index: 9 !important;
}

.home-header-sideNav .sidebar {
  /* height: auto;
  position: absolute;
  top: 0;
  left: 0; */
  /* height: 1024px; */
  background-color: var(--white);
  padding-top: 24px;
  padding-left: 16px;
  color: white;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: 0.3s;
  box-shadow: 2px 0px 9px 0px rgba(0, 0, 0, 0.07);
  position: relative;
  width: 100%;
}

.home-header-sideNav .sidebar .mobile-menu-content {
  height: 100%;
}

.home-header-sideNav .sidebar .mobile-menu-content .sb-top-dov {
  /* overflow: auto; */
  /* height: calc(60% - 160px); */
  height: 145px;
}

.home-header-sideNav .sidebar .mobile-menu-content .sb-top-dov .mobile-menu-contentTop-bar {}

.home-header-sideNav .sidebar .mobile-menu-content .sb-top-dov .sb-options-div-cont {}

.home-header-sideNav .sidebar .mobile-menu-content .yourEventCont {
  height: calc(100% - 425px);
}

.home-header-sideNav .sidebar .mobile-menu-content .yourEventCont .yourEventTop {
  height: 87px;
}

.sb-options-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sb-divider {
  background-color: var(--lightGrey);
  /* margin-top: 40px;
  margin-bottom: 40px; */
  /* margin-top: 5%; */
  margin-bottom: 5%;
  margin-left: -16px;
  height: 1px;
  width: calc(100% + 16px);
}

.home-header-sideNav .sidebar .mobile-menu-content .sb-scroll-container {
  /*margin-top: 32px; */
  margin-top: 4%;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 7px;
}

.home-header-sideNav .sidebar .mobile-menu-content .SideBarEventCont {
  height: auto;
  /* max-height:395px;  */
  /* max-height: 380px; */
  /* padding-top: 16px; */
  height: calc(100% - 87px);
  overflow-y: auto;
  /* padding-bottom: 144px; */
  /* need to adjust this above "padding-bottom" and "height" props to make sure the scrollbar is not cut off */
  /* scrollbar-width: none;
  -ms-overflow-style: none; */
  /* -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; */
  /* scrollbar-color: var(--brand-primary-brand) #f5f5f5;
  scrollbar-width: thin; */
}

/* .home-header-sideNav .sidebar .mobile-menu-content .SideBarEventCont::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
}
.home-header-sideNav .sidebar .mobile-menu-content .SideBarEventCont::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #b9b9b9;
  background-color: var(--brand-primary-brand);
}
.home-header-sideNav .sidebar .mobile-menu-content .SideBarEventCont::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f5f5f5;
} */
.home-header-sideNav .sidebar .mobile-menu-content .bottom-sideBar-loginProfile-block {
  background-color: #fff;
  /* position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 10px; */
  height: 17%;
  min-height: 160px;
  width: 100%;
}

.sb-events-column {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;
}

.sb-add-event-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
}

.sb-indicator-view {
  width: 4px;
  height: 60px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: var(--midBlue);
}

.sidebar.mobile-menu-content {
  height: 100%;
}

.bottom-sideBar-loginProfile-block .generic-common-row-div-space-between {
  padding: 19px 0px;
  border-top: 1px solid #EBEBEB;
}

@media (max-width:767px) {
  .sidebar .sb-scroll-container-vertical {
    height: auto;
  }

  .bottom-sideBar-loginProfile-block {
    /* position: inherit;
    bottom: inherit;
    margin: 40px 0 0; */
  }

  .bottom-sideBar-loginProfile-block .generic-common-row-div-space-between {
    padding: 15px 0px;
    margin: 35px 0 0;
  }

  .mobile-menu-content .sb-top-dov .sb-divider {
    margin-top: 10px;
    margin-bottom: 16px;
  }

  .home-header-sideNav .sidebar .mobile-menu-content .SideBarEventCont{margin:22px 0 0; height: calc(100% - 117px);}

}