:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
  --midGrey: #bebebe;
  --veryDarkBlue: #063279;
  --lightStateBlue: #f6f9fe;
  --grey: #767676;
  --red: #dd3139;
  --black: #000000;
  --hoverGreen: #e7f3ec;
}

.central-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 34, 68, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.central-modal {
  background: white;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 48px);
  max-height: 90vh;
}

.central-modal-custom {
  background: white;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  margin-left: 260px;
  margin-right: 260px;
  /* margin-top: 110px;
  margin-bottom: 110px; */
  width: 100%;
  /* height: 85%; */
  /* flex: 1; */
  /* max-height: 85%; */
  max-height: 668px;
  max-width: 871px;
  /* overflow-y: auto; */
}

.order-cancelling-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0D2244F2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.order-cancelling-modal-content {
  background: white;
  padding: 10px 20px;
  padding-bottom: 20px;
  border-radius: 24px;
  width: 692px;
  max-height: 95%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 11;
  overflow-y: auto;
}

.order-cancelling-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #ddd; */
  /* padding-bottom: 10px; */
}

.order-cancelling-modal-header h2 {
  margin: 0;
}

.order-cancelling-close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.order-cancelling-modal-body {
  margin-top: 24px;
}

.order-cancelling-details-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.order-cancelling-details-row {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  border-radius: 24px;
  padding: 12px 20px;
  border: 1px solid var(--surfaceDisabled);
}

.order-cancelling-summary-table {
  width: 100%;
  /* border-collapse: collapse; */
  margin: 24px 0;
  border-radius: 24px;
  padding: 12px 24px;
  border: 1px solid var(--surfaceDisabled);
}

.order-cancelling-summary-table th, .summary-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.order-cancelling-summary-table th {
  background-color: #f9f9f9;
}

.order-cancelling-agreement {
  margin: 20px 0;
  font-size: 14px;
}

.order-cancelling-modal-footer {
  display: flex;
  justify-content: space-between;
}

.order-cancelling-cancel-btn {
  background-color: white;
  color: red;
  border: 1px solid red;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.order-cancelling-confirm-btn {
  background-color: lightgray;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.central-modal-custom.central-modal-add-payment-method {
  max-width: 692px;
}

.central-modal-custom.central-modal-invoice-history {
  max-width: 919px;
  /* max-height: 1144px; */
}

.central-modal-dynamic-size {
  background: white;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 32px;
  /* padding-bottom: 40px; */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  /* margin-left: 260px;
  margin-right: 260px; */
  margin-left: 170px;
  margin-right: 170px;
  /* max-width: 800px; */
  max-width: 692px;
  /* margin-top: 110px;
  margin-bottom: 110px; */
  /* width: 100%;
  flex: 1;
  /* overflow-y: auto; */
}

.central-modal-content {
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 42px);
  /* overflow-y: scroll; */
  overflow: auto;
  /* Add your custom styles for the modal content here */
}

.central-input-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--lightGrey);
  border-radius: 4px;
  height: 124px;
  align-items: "center";
  width: 626px;
}

.central-modal-close {
  /* display: block; */
  align-self: flex-end;
}

.central-top-aligned-input {
  font-family: "Poppins Regular 400", sans-serif;
  font-size: 16px;
  color: var(--midnightBlue);
}

.central-bottom-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 16px;
}

.central-modal-custom .option-container .generic-common-row-div {
  padding: 0 15px 0 0;
}

.slelectPay-now-item .generic-common-row-div .img-payCard {
  display: flex;
}

@media (max-width:1400px) {

  .central-modal-custom {
    margin-left: 90px;
    margin-right: 90px;
  }

}

@media (max-width:1024px) {

  .slelectPay-now-item .generic-common-row-div {
    flex-wrap: wrap;
  }

  .slelectPay-now-item .generic-common-row-div .default-tag {
    padding: 4px 0 0 58px;
  }

  .central-modal-dynamic-size {
    margin-left: 55px;
    margin-right: 55px;
  }

}


@media (max-width: 767px) {
  .central-modal-custom {
    border-radius: 0px;
    height: 100%;
    padding: 16px;
    margin-left: 0px;
    margin-right: 0px;
    overflow: auto;

    position: absolute;
    top: 0;
    height: 100%;
    max-height: 100%;
  }

  .order-cancelling-modal-content {
    width: 100%;
    height: 100%;
    margin: 40px 16px;
    overflow: auto;
  }

  .order-cancelling-details-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .order-cancelling-details-row {
    width: 100%;
  }

  .page-wrapper-event-details .central-modal-custom{position:initial; height:85%; box-sizing:border-box; width:90% !important;}

  .central-modal-custom .generic-common-column-div .generic-common-row-div-space-between {
    flex-direction: column;
  }

  .central-modal-contentPopup-topList .contentPopup-topList-item {
    margin-left: 0px !important;
  }

  .central-modal-contentPopup-topList {
    margin-top: 40px !important;
    margin-bottom: 32px !important;
  }

  .contentPopup-topList-item-innerTittle {
    margin-bottom: 12px !important;
  }

  .contentPopup-topList-item {
    margin-bottom: 24px !important;
  }

  .central-modal-contentPopup-bottomList {
    height: auto !important;
    position: relative;
  }

  .central-modal-contentPopup-bottomList .generic-common-row-div-space-between {
    flex-direction: row !important;
  }

  .contentPopup-bottomList-inner {
    flex-direction: row !important;
  }

  .central-modal-contentPopup-check {
    flex-wrap: nowrap !important;
  }

  .central-modal-contentPopup-confirm-btn div#box1:first-child {
    margin-left: 0px !important;
  }

  .central-modal-contentPopup-confirm-btn div#box1 {
    margin: 0 !important;
  }

  .central-modal-contentPopup-confirm-btn {
    margin-bottom: 22px !important;
  }

  .central-bottom-button-container {
    flex-direction: column !important;
    gap: 16px;
  }

  .central-bottom-button-container>.Box1 {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .central-modal.ModalAvatar {
    border-radius: 4.139px !important;
  }

  .central-modal.ModalAvatar .ModalAvatarImg {
    border-radius: 4.139px !important;
  }

  .central-modal.ModalAvatar .ModalAvatarCloseBtn {
    top: 16px !important;
    right: 16px !important;
  }

  .central-modal-custom.central-modal-invoice-history .paymenyInfo-description-block {
    flex-wrap: wrap;
  }

  .central-modal-custom.central-modal-invoice-history .paymenyInfo-description-block .generic-common-column-div {
    width: 100% !important;
    margin: 0 0 20px !important;
  }

  .edit-threshold-popup-innerContent .generic-common-column-centered-div .generic-common-column-div {
    width: 92% !important;
  }

  .central-modal-contentPopup-bottomList.edit-threshold-popup-innerContent .generic-common-row-centerd-div {
    flex-wrap: wrap;
  }

  .central-modal-contentPopup-bottomList.edit-threshold-popup-innerContent .generic-common-row-centerd-div .Box1 {
    margin: 0 0 15px !important;
  }

  .central-modal-custom.central-modal-add-payment-method {
    height: auto;
    top: initial;
    max-height: 660px !important;
    min-height: 660px;
    width: 95% !important;
    border-radius: 8px !important;
    padding: 24px;
  }

  .central-modal-custom.central-modal-add-payment-method .generic-common-row-div-top {
    flex-wrap: wrap;
  }

  .central-modal-custom.central-modal-add-payment-method .generic-common-row-div-top .generic-common-column-div-half-width {
    margin: 0 !important;
    width: 100%;
  }

  .central-modal-custom.central-modal-add-payment-method .generic-common-row-div-space-between {
    flex-wrap: wrap;
  }

  .central-modal-custom.central-modal-add-payment-method .generic-common-row-div-space-between .Box1 {
    margin: 0px 0 15px !important;
  }

  .central-modal-custom.central-modal-add-payment-method .modal-add-payment-method-title span {
    font-size: 24px !important;
    max-width: 250px;
  }

  .central-modal-custom.central-modal-add-payment-method .central-modal-contentPopup-bottomList .generic-common-row-div-top .generic-common-column-div-half-width span {margin:14px 0 0 !important;}

  .central-modal-custom.central-modal-add-payment-method .central-modal-contentPopup-bottomList .generic-common-row-div-top .generic-common-column-div-half-width .containerInput{margin:5px 0 0 !important;}

  .central-modal-content .discount-code-empty-container .RadioRow{display:flex !important; flex-direction:column !important;}
  .central-modal-content .discount-code-empty-container .RadioRow .option-container{margin-right:0px !important;}
    .central-modal-content .discount-code-empty-container .generic-common-column-div-half-width{width:100%; padding-left:0px !important; margin-bottom:9px;}
    .central-modal-custom .modal-add-payment-method-title span{font-size:22px !important;}

    /* css start 09-08-24 */

    .resize-poster-overlay .central-modal-custom{position:initial; height:auto; max-width:92%;}
    .resize-poster-overlay .reactEasyCrop_CropArea{width:100% !important;}
    .resize-poster-overlay .crop-event-banner-container{width:100% !important;}
    .resize-poster-overlay .account-verify-setup-container{width:100% !important;}

    /* css end 09-08-24 */

}


@media (max-width:575px) {

  .slelectPay-now-items-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .slelectPay-now-item .generic-common-row-div .img-payCard {
    align-items: center;
  }

  .central-modal-custom .central-modal-contentPopup-bottomList .generic-common-row-div {
    flex-wrap: wrap;
  }

  .central-modal-custom .central-modal-contentPopup-bottomList .generic-common-row-div .amount-pay-container {
    margin: 8px 0 !important;
  }

  .central-modal-dynamic-size {
    margin-left: 15px;
    margin-right: 15px;
  }

  .edit-threshold-popup-innerContent .generic-common-column-centered-div .generic-common-column-div {
    width: 85% !important;
  }

  .central-modal-dynamic-size {
    max-height: 640px;
  }

    .central-modal-custom .central-modal-content{}

}

.central-modal.ModalAvatar {
  border-radius: 8px;
  height: auto !important;
}