:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --midnightBlue: #002366;
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
  --midnightBlue: #002366;
  --green: #0d873c;
  --red: #dd3139;
  --hoverFadeBlue: #eaf1fc;
  --iceWhite: #fff;
  --fuchsiaSecondary: #cc0ccf;
}

.page-wrapper-event-details {
  position: relative;
  min-height: 100vh;
  content: "";
  background-color: var(--veryLightGrey);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  background-position: bottom;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* .header-event-details {
  display: flex;
  flex-direction: row;

} */

.event-details-content-div {
  display: flex;
  flex-direction: column;
  padding-bottom: 64px;
}

.crop-banner-for-square .reactEasyCrop_Container {
  background-color: #f1f1f1;
  width: 393.75px;
  margin: 0 auto;
}

.attendee-email-box-container {
  /* display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 24px;
  background: var(--iceWhite);
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  margin-top: 24px;
  width: 100%;
  border: 1px solid var(--surfaceDisabled); */
  display: flex;
padding: 20px 24px;
flex-direction: column;
align-items: flex-start;
flex: 1 0 0;
border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.attendee-email-status-box-container {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 24px;
  background: var(--surface-secondary);
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  margin-top: 24px;
  border: 1px solid var(--surfaceDisabled); */
  display: flex;
padding: 20px 24px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
align-self: stretch;
background: var(--surface-secondary);
border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  max-width: 278px;
}

.attendee-email-history-box-container {
  display: flex;
align-items: flex-start;
align-self: stretch;
border-radius: 24px;
border: 1px solid var(--surfaceDisabled);
margin-top: 20px;
background: var(--iceWhite);

}

.compose-email-attendee-warning-container {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  background-color: var(--surface-warning-light);
  padding: 12px 16px;
  border-radius: 24px;
  width: 736px;
}

.compose-email-parent-text-box-container {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  margin-top: 32px;
}

.compose-email-child-text-box-container {
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
}

.compose-email-attendee-agree-terms-container {
  display: flex;
  width: 618px;
  min-height: 48px;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  align-self: center;
  margin-top: 20px;
}

.compose-email-attendee-confirm-button-container {
  display: flex;
justify-content: center;
align-items: flex-start;
gap: 16px;
align-self: stretch;
margin-top: 24px;
}

.event-description-input-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px !important;
  border-style: solid;
  border-width: 1px;
  border-color: var(--lightGrey);
  border-radius: 4px;
  height: 124px;
  align-items: "center";
}

.event-description-top-aligned-input {
  font-family: "Poppins Regular 400", sans-serif;
  font-size: 16px;
  color: var(--midnightBlue);
}

.event-details-banner-parent-content {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 40px;
  margin-left: 24px;
  margin-right: 24px;
  border-color: var(--lightGrey) !important;
  border-width: 1px !important;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 8px;
  background-color: var(--white);
  gap: 24px;
}

.event-details-instance-component-parent-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-top: 40px;
}

.event-details-pie-chart-parent-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 40px;
  margin-bottom: 64px;
  margin-left: 24px;
  margin-right: 24px;
  /* height: 558px;  */
  background-color: var(--white);
  padding-top: 40px;
  padding-left: 24px;
  padding-right: 32px;
  padding-bottom: 40px;
  border-radius: 8px;
  border-color: var(--lightGrey) !important;
  border-width: 1px !important;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
  position: relative;
}

.event-details-centered-div-into-pie-chart {
  position: absolute;
  left: 12px;
  top: 84px;
  width: 270px;
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event-details-centered-div-into-pie-chart-right {
  position: absolute;
  right: 172px;
  top: 84px;
  width: 270px;
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.draft-banner-wrapper {
  display: flex;
  overflow: hidden;
  position: relative;
  flex: 1;
  box-sizing: border-box;
  padding: 12px 24px;
  height: 48px;
  background-color: var(--background-color);
}

.draft-banner-container {
  display: inline-flex;
  flex-direction: row;
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: scroll 35s linear infinite;
}

.draft-banner-item {
  display: inline-flex;
  align-items: center;
  padding-right: 50px;
  /* Gap between repeated items */
}



.event-details-chart {
  width: 100%;
  height: auto;
}

.event-details-top-innerMenu {
  /* padding: 6px 0px !important; */
  /* z-index: -1; */
}

.event-details-topMneu-lft {}

.event-details-topMneu-rht .Box1 {
  width: 150px !important;
}

.event-detailsTop-btns-inner {
  display: flex;
  /* flex-wrap: wrap; */
  padding-bottom: 10px;
}

.event-details-banner-parent-content div div img {
  border-radius: 8px !important;
}

.eventDetails-item-inner-content .event-details-tittle {
  margin: 32px 0 16px;
}

.eventDetails-item-inner-content .event-details-tittle span {
  font-size: 24px !important;
}

.eventDetails-item-inner-content .event-details-prg {}

.eventDetails-item-inner-content .event-details-prg span {
  font-size: 16px !important;
}

.event-details-banner-parent-content .event-details-instance-component-parent-content svg {
  width: 30px !important;
  height: 30px !important;
}

/* mobile menu css start */
.mobile-menu-bar {
  display: none;
}

.mobile-menu-contentTop-rht {
  display: none;
}

/* mobile menu css end */

.eventDetails-slick-container {
  display: block !important;
}

.eventDetails-slick-container .live-event-statistics-left-parent-container {
  display: flex !important;
  /* padding-right: 64px; */
}

.live-event-statistics-inner-chart-container {
  position: relative;
}

/* .eventDetails-slick-container .event-details-centered-div-into-pie-chart{
    top:0;
    left:-12px;
  } */

/* .event-details-inner-imgContainer img {
    object-fit: contain !important;
    background: #000;
} */

.event-details-content-div .event-about-div .generic-common-column-div .generic-common-row-div.CommonFormFieldRow .Box1 {
  margin-left: 32px;
}

.event-about-div.CommonBoxCont .bottom-buttons-container-edit-view {
  padding: 0 15px;
}



/* css start 07-11-24 */
.account-setup-requiredBlock{
  flex-wrap:wrap !important;
}
    .account-setup-required-lft{
      width:50%;
    }
    .account-setup-required-rht {
      width: 50%;
      flex-wrap: wrap;
      row-gap: 15px;
      padding: 0 0 0 19px;
      column-gap: 3%;
  }

    .account-setup-required-rht .Box1 {
      width: auto !important;
      margin: 0 !important;
      height: auto;
      padding:12px 15px;
      
  }

    .account-setup-required-rht .Box1 span{
      text-wrap:wrap !important;
      text-align:center !important;
    }

   
/* css end 07-11-24 */

@media (max-width:1400px) {

  .account-setup-required-rht .Box1{
    flex-grow:1;
  }

}


@media (max-width: 1024px) {
  .event-details-topMneu-rht .Box1 {
    width: 120px !important;
  }

  .draft-banner-container {
    left: auto;
  }

  .event-details-top-innerMenu {
    /* padding: 6px 10px 6px 0 !important; */
    flex-wrap: wrap;
  }

  .generic-common-row-div-space-between.guestList-ticket-block {
    flex-wrap: wrap;
  }

  .generic-common-row-div-space-between.guestList-ticket-block .guestList-ticket-item {
    width: 100% !important;
    margin-right: 0px !important;
  }

  .generic-common-row-div-space-between.guestList-ticket-block .generic-common-row-div-space-between.guestList-ticket-item {
    flex-wrap: wrap;
  }

  .generic-common-row-div-space-between.guestList-ticket-block .generic-common-row-div-space-between.guestList-ticket-item .guestList-status {}

  .generic-common-row-div-space-between.guestList-ticket-block .generic-common-row-div-space-between.guestList-ticket-item .Box1 {
    width: 100% !important;
    margin: 30px 0 0 !important;
    padding: 0 0 0;
  }

 

}

@media (max-width: 768px) {
  /* .header-event-details {
    flex-direction: column;
  } */

  .draft-banner-container {
    left: auto;
  }

  .side-bar-class {
    width: 100%;
    transition: transform 0.3s ease;
    transform: translateX(-100%);
  }

  .side-bar-visible {
    transform: translateX(0);
  }

  .event-details-content-div {
    margin-left: 0;
    margin-top: 0;
  }

  .event-details-banner-parent-content,
  .event-details-instance-component-parent-content,
  .event-details-pie-chart-parent-content {
    flex-direction: column;
    /* margin-left: 16px;
    margin-right: 16px; */
  }

  .event-details-centered-div-into-pie-chart,
  .event-details-centered-div-into-pie-chart-right {
    /* position: static;
    width: 100%;
    left: 0;
    right: 0;
    margin-top: 16px; */
  }

  .event-statistics-text {
    font-size: smaller;
  }
}

@media (max-width: 767px) {

  .compose-email-parent-text-box-container
  {
    flex-direction: column;
    gap: 16px;
    margin-top: 0px;
  }

  .compose-email-child-text-box-container
  {
    gap: 0px !important;
    width: 100%;
  }

  .compose-email-attendee-warning-container
  {
    width: 100%;
  }

  .compose-email-attendee-agree-terms-container
  {
    width: 100%;
  }

  .email-attendee-top-div .add-on-empty-container-event-details {
    padding: 20px !important;
    margin: 20px !important;
  }

  .compose-email-parent-text-box-container .event-description-input-container {
    margin-bottom: 8px !important;
  }

  .email-attendee-top-div .add-on-empty-container-event-details .generic-common-divider {
      margin-top: 20px !important;
  }

  .attendee-email-history-box-container {
    flex-direction: column;
  }

  .attendee-email-status-box-container {
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 24px;
    max-width: 100%;
  }

  .compose-email-attendee-confirm-button-container
  {
    width: 100%;
    flex-direction: column;
  }

  .compose-email-attendee-inner-box-container
  {
    padding: 20px !important;
  }

  .event-details-content-div {
    padding-right: 0px;
  }

  body .event-details-top-innerMenu {
    padding: 20px 16px 0 !important;
    margin: 0 !important;
  }

  .event-details-top-innerMenu .event-details-topMneu-lft {
    order: 2;
    padding: 30px 0 0;
  }

  .event-details-top-innerMenu .event-details-topMneu-rht {
    order: 1;
    justify-content: flex-end;
  }

  .event-details-top-after-menulink-container {
    padding: 0 16px 25px !important;
  }

  .event-details-top-after-menulink-container .event-details-event-scroll-container {
    overflow: auto;
  }

  .event-details-event-scroll-container .event-detailsTop-btns-inner {
    display: flex;
    width: 820px;
    flex-wrap: nowrap;
  }

  .event-details-top-after-menulink-container .event-details-event-scroll-container .create-event-btnCustom {
    display: flex;
  }

  .event-details-top-after-menulink-container .event-details-event-scroll-container {
    margin-top: 24px;
  }

  .home-scroll-container {
    margin-top: 32px;

    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .home-scroll-container.homeTob-btns-tool-lft {
    width: 100%;
    order: 2;
    display: flex;
    margin: 0px;
    /* flex-wrap: wrap; */
    gap: 0 8px;
    /* padding: 0px !important; */
  }

  .event-details-tittle {
    margin: 24px 0 0;
  }

  .event-details-tittle span {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .event-details-topMneu-rht .Box1 {
    width: auto !important;
    margin: 0px !important;
  }

  .event-details-banner-parent-content {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 0px;
    box-shadow: inherit;
  }

  .event-details-banner-parent-content div {
    /* margin-right: 0px !important; */
  }

  .event-details-banner-parent-content .left-tags-label-container-event-details .create-event-btnCustom {
    width: auto;
    height: auto;
    border-radius: 4px !important;
  }

  .event-details-banner-parent-content .left-tags-label-container-event-details .create-event-btnCustom span {
    font-size: 14px !important;
  }

  .left-tags-label-container-event-details {
    /* gap: 0 8px;
    padding: 32px 0 0; */
  }

  .eventDetails-item-inner-content .event-details-tittle {
    /* margin: 17px 0 16px; */
  }

  .event-details-banner-item-icon {
    padding: 0 16px 0 0;
  }

  .page-wrapper-event-details .event-details-content-div .event-details-pie-chart-parent-content {
    background: #ffffff;
    padding: 40px 16px;
    margin: 40px 0 0;
    border-radius: 0px;
    border: 1px solid #ebebeb;
  }

  .page-wrapper-event-details .event-details-content-div .event-details-pie-chart-parent-content div#box1 {
    margin: 26px 0 0;
  }

  /* mobile menu css start */
  .sidebar.sidebar-for-mobile {
    /* height: auto; */
  }

  .mobile-menu-bar {
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    background: #fff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
    align-items: center;
  }

  .mobile-menu-contentTop-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
  }

  .mobile-menu-contentTop-rht {
    display: block;
  }

  .mobile-menu-content .sb-divider {
    /* display:none; */
    background: none;
  }

  .mobile-menu-contentTop-lft svg {
    width: 45px !important;
    height: 45px !important;
  }

  .mobile-menu-content {
    position: fixed;
    z-index: 99;
    background: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 18px 19px;
    transform: translate(-100%, 0%);
    transition: all 0.95s ease;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .mobile-menu-content.show {
    transform: translate(0%, 0%);
  }

  .mobile-menu-contentTop-rht svg {
    transform: rotate(45deg);
  }

  /* mobile menu css end */

  .event-details-top-after-menulink-container span {
    font-size: 24px;
    display: block;
    margin: 2px 0 0 !important;
  }

  .event-details-top-after-menulink-container .create-event-btnCustom span {
    margin: 0px !important;
  }

  .generic-common-row-div-space-between.guestList-contentBtn-block {
    flex-wrap: wrap;
  }

  .generic-common-row-div-space-between.guestList-contentBtn-block .guestList-content-lft {
    width: 100% !important;
    margin: 0px !important;
  }

  .generic-common-row-div-space-between.guestList-contentBtn-block .guestList-content-lft .guestList-tittle {
    font-size: 24px !important;
  }

  .generic-common-row-div-space-between.guestList-contentBtn-block .guestList-btn-rht {
    width: 100% !important;
    margin: 0px !important;
    padding: 32px 0 0;
  }

  .generic-common-row-div-space-between.guestList-ticket-block {
    flex-wrap: wrap;
  }

  .generic-common-row-div-space-between.guestList-ticket-block .guestList-ticket-item {
    width: 100% !important;
    margin-right: 0px !important;
  }

  .generic-common-row-div-space-between.guestList-ticket-block .generic-common-row-div-space-between.guestList-ticket-item {
    flex-wrap: wrap;
  }

  .generic-common-row-div-space-between.guestList-ticket-block .generic-common-row-div-space-between.guestList-ticket-item .guestList-status {}

  .generic-common-row-div-space-between.guestList-ticket-block .generic-common-row-div-space-between.guestList-ticket-item .Box1 {
    width: 100% !important;
    margin: 30px 0 0 !important;
    padding: 0 0 0;
  }

  .central-modal-content .discount-code-empty-container {
    align-items: initial;
  }

  .discount-bottom-discard-container {
    flex-direction: column !important;
  }

  .discount-bottom-discard-container .Box1 {
    margin: 0 0 12px !important;
  }

}

.add-on-empty-container-event-details {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--lightGrey);
  background: var(--white);
  margin: 36px 28px;
  border-radius: 8px;
}

.email-attendee-top-div .add-on-empty-container-event-details {
  padding: 24px;
}

.discount-empty-container-event-details {
  display: flex;
  padding: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--lightGrey);
  background: var(--white);
  margin: 24px 28px;
  border-radius: 8px;
}

.compose-email-attendee-inner-box-container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 24px;
border: 1px solid var(--surfaceDisabled);
margin-top: 32px;
}

.discount-code-empty-container {
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--lightGrey);
  background: var(--white);
  border-radius: 8px;
  margin-bottom: 24px;
}


@media(max-width:575px) {

  .event-detailsTop-btns-inner .tags-inner-container span {
    font-size: 13px !important;
  }

  .account-setup-required-lft {
    width: 100%;
  }
  .account-setup-required-rht {
    width: 100%;
    padding: 22px 0 0;
  }

}