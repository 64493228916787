.payment-verified-container {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--lightGrey);
  background-color: var(--white);
  margin-top: 40px;
}

.payment-row-outer {
  display: flex;
  /* padding: 16px; */
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--lightGrey);
  background-color: var(--white);
  margin-top: 24px;
  width: 100%;
}

.payment-row-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid var(--lightGrey);
}


@media screen and (max-width: 767px) {
  /* css start 17-04-24 */

  .tooltip-parent-container-content {
    position: fixed !important;
    bottom: 0;
    height: 156px;
    box-shadow: 0px -4px 6px 1px rgba(0, 0, 0, 0.08) !important;
    border-radius: 8px 8px 0px 0px !important;
    /* background-color: red !important; */
    bottom:-6px !important;
    top: inherit !important;
    left: 0 !important;
    width: 100% !important;
    transform: translate(0%, 110%);
    transition: all 0.95s ease;
  }

  .tooltip-parent-container-content.show1-content {
    transform: translate(0%, 0%);
  }

  @media screen and (max-width:1239px) {

    .paymentMethod-inner-cardDefault {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
    .paymentMethod-inner-Default-rht {
      margin: 7px 0 7px;
    }
    

  }


  .tooltip-parent-container-content .close-custom-tooltip {
    /* width: 100%; */
    text-align: center;
    display: inline-block;
    margin: 19px auto 0;
    padding: 6px 10px;
  }

  .tooltip-parent-container-content .generic-common-column-div .generic-common-column-div {
    padding: 0px 0 2px;
  }

  /* css end 17-04-24 */

}

@media (min-width: 768px) {
  .desktop-hide-close-custom-tooltip {
    display: none;
  }
}


@media screen and (max-width:575px) {

  .paymentMethod-inner-cardDefault {
    flex-direction: column !important;
  }

  .paymentMethod-inner-Default-rht {
    margin: 7px 0 7px;
  }

  .payment-row-inner{flex-direction: column !important;}

}