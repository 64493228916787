.ticket-input-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--lightGrey);
  border-radius: 4px;
  height: 124px;
  align-items: "center";
}

.ticket-top-aligned-input {
  font-family: "Poppins Regular 400", sans-serif;
  font-size: 16px;
  color: var(--midnightBlue);
  max-width: 100%;
}
