@tailwind base;
@tailwind components;
@tailwind utilities;

body, p, div, span, h1, h2, h3, h4, h5, h6 {
  line-break:normal !important;
}

body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 
    sans-serif; */
  font-family: "Poppins", sans-serif, "Roboto";
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:target {
  border: 0 !important;
  border-width: 0px;
  box-shadow: inherit;
  outline: none;
  border-style: none;
}

.common-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: aqua;
}

.gm-style-iw.gm-style-iw-c,
.gm-style-iw-tc {
  margin-top: 5px;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden !important;
}

.gm-style-iw-d {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

:root {
  /* fonts */
  --global-font-family: "Poppins";
  --body-xl: "Poppins";
  --half-width: 50%;
  --full-width: 100%;
  --full-height-150: 150%;
  --full-width-90: 90%;
  --height-125: 125%;
  --margin-top: 5%;
  /* --body-xl: "Work Sans", sans-serif;
  --action-sm: "Space Mono", monospace;
  --display-md: "Mark-Heavy", sans-serif;
  --text-md-semibold: "Inter", sans-serif; */
  /* font sizes */
  --body-md-size: 16px;
  --font-size: 17px;
  --body-xs-size: 12px;
  --body-lg-size: 18px;
  --body-lg-size-80: 80px;
  --display-md-size: 36px;
  --display-md-size-32: 32px;
  --body-sm-size: 14px;
  --body-xl-size: 20px;
  --body-xl-64: 64px;
  --body-xl-56: 56px;
  --body-xl-52: 52px;
  --display-lg-md: 40px;
  --display-lg-size: 48px;
  --display-xs-size: 24px;
  --display-sm-size: 30px;
  --font-w-400: 400;
  --font-w-500: 500;
  --font-w-600: 600;
  --font-w-700: 700;

  /* Colors */
  --monochrome-white: #fff;
  --monochrome-black: #000;
  --monochrome-light-grey: #f7f7f7;
  --gray-200: #eaecf0;
  --monochrome-grey: #e9e9e9;
  --brand-primary-brand: #0057FF;
  --twotixx-borders-silver: #ebebeb;
  --twotixx-primary-electric-blue: #0057FF;
  --twotixx-text-midnight-blue: #002366;
  --twotixx-notifications-light-information-blue: #eaf1fc;
  --twotixx-white: #fbfbfb;
  --monochrome-dark-grey-2: #a7a7a7;
  --color-plum: #f8b4e4;
  --gray-900: #101828;
  --app-header-color: #282c34;
  --app-link-color: #61dafb;
  --twotixx-grey: #767676;
  --twotixx-turbo-blue: #4693f5;
  --red: rgb(252, 1, 1);
  --twotix-border-gray: #cacaca;
  --twotix-light-blue: #c0d5f6;
  --light-blue: #4688f5;
  --twotix-pink: #cc0ccf;
  --veryLightBlue: #46aff5;
  --lightBlue: #4688f5;
  --midBlue: #0057FF;
  --darkBlue: #1458c2;
  --veryDarkBlue: #063279;
  --midnightBlue: #002366;
  --fuchsia: #ca22a5;
  --teal: #18cbc0;
  --black: #000000;
  --veryDarkCharcoal: #111111;
  --darkCharcoal: #1d1d1d;
  --charcoal: #222222;
  --veryDarkGrey: #636363;
  --darkGrey: #969696;
  --midGrey: #bebebe;
  --lightGrey: #ebebeb;
  --grey: #767676;
  --veryLightGrey: #fbfbfb;
  --white: #ffffff;
  --green: #0d873c;
  --greenSuccess: #097E07;
  --yellow: #ffb800;
  --red: #dd3139;
  --hoverRed: #fceaeb;
  --blue: #0f5bcd;
  --gradientStartBtn: #498fc1;
  --helperTextGrey: #00000054;
  --darkLava: #333333;
  --hoverBlue: #e7f0fe;
  --hoverFadeBlue: #eaf1fc;
  --hoverGrey: #eaeaea;
  --hoverStateBlue: #cfe0fc;
  --lightGreen: #f6fef9;
  --hoverGreen: #e7f3ec;
  --lightYellow: #fefaf6;
  --lightRed: #fff0f1;
  --lightStateBlue: #f6f9fe;
  --iceWhite: #fff;
  --fuchsiaSecondary: #cc0ccf;
  --hoverGrey: #f1f1f1;
  --hoverStateGreen: #f1f8f4;
  --hoverOrange: #fdf7ed;
  --hoverRed: #fef2f3;
  --hoverStateMidBlue: #f0f6fe;
  --orange: #d97126;
  --surfaceDisabled: #dcdfe4;
  --textDisabled: #626f86;
  --newTextBody: #44546F;
  --surfaceLight: #E5EEFF;
  --surfaceSuccessLight : #E6F2E6;
  --contentError: #D51010;
  --surface-secondary :#F1F2F4;
  --surface-warning-light: #F8EDE6;
  --text-body-warning: #B64D02;
  --surfaceSecondary: #F1F2F4;

  /* Gaps */
  --gap-9xs: 4px;
  --gap-7xs: 6px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-xs-12: 12px;
  --gap-base: 16px;
  --gap-xl: 20px;
  --gap-5xl: 24px;
  --gap-13xl: 32px;
  --gap-23xl: 42px;
  --gap-31xl: 50px;
  --gap-45xl: 64px;

  /* Paddings */
  --padding-45xl: 64px;
  --padding-29xl: 48px;
  --padding-13xl: 32px;
  --padding-30: 30px;
  --padding-14xl: 32px 0 0;
  --padding-f-s: 40px;
  --padding-11xs: 2px;
  --padding-5xs: 8px;
  --padding-sm: 14px;
  --padding-sm-12: 12px;
  --padding-3xs: 10px;
  --padding-base: 16px;
  --padding-xl: 20px;
  --padding-5xl: 24px;
  --padding-8xl: 80px;
  --padding-90: 90px;
  --padding-72: 72px;
  --padding-9: 9px;
  --padding-btn: 0 0 0 10px;
  --padding-btn-1: 0 10px 0 0;
  --padding-0: 0px;
  --padding-45s: 45px;
  --padding-15x: 15px;
  --padding-13px: 13px;
  --padding-mx: 24px 13px;
  --padding-border: 1px 0px 0px 0px;
  --padding-border-15x: 0 15px 15px;
  --padding-25x: 25px;
  --padding-57px: 57px;
  --padding-56px: 56px;
  --padding-66px: 66px;
  --padding-128px: 128px;
  --padding-115px: 115px;
  --padding-112px: 115px;
  --padding-28px: 28px;
  --padding-521px: 521px;
  --padding-189px: 189.5px;
  --padding-197px: 197.5px;
  --padding-0s: 0;
  --padding-200px: 200px;
  --padding-38px: 38px;
  --padding-36px: 36px;
  --padding-26px: 26px;
  --padding-300px: 300px;
  --padding-910px: 910px;
  --padding-1440px: 1440px;
  --padding-1226px: 1226px;
  --padding-1280px: 1280px;

  /* Border radiuses */
  --br-8xs: 5px;
  --br-6xs: 7px;
  --br-9xs: 4px;
  --br-5xs: 8px;
  --br-24: 24px;
  --br-m: 8px 8px 0px 0px;

  /* line height */
  --line-hight-lg: 96px;
  --line-hight-100: 100px;
  --line-hight-90: 90px;
  --line-hight-80: 80px;
  --line-hight-70: 70px;
  --line-hight-72: 72px;
  --line-hight-40: 40px;
  --line-hight-36: 36px;
  --line-hight-32: 32px;
  --line-hight-md: 56px;
  --line-hight-sm: 28px;

  /* width */
  --box-width: 306px;
  --width-sidebar: 350px;
  --width-without-sidebar: calc(100% - 350px);
  /* --br-5xs: 4px; */
  --action-sm: "Poppins";
  --shadow-xs: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --shadow-xl: 0px 4px 16px 0px rgba(0, 0, 0, 0.24);
  --shadow-xxl: 0px -2px 8px 0px rgba(0, 0, 0, 0.24);
  --padding-xs: 8px;
  --padding-9xl: 16px;
  --gap-xs: 4px;
  /* --body-lg-size: 16px; */
  /* --body-md-size: 14px; */
  /* --body-sm-size: 12px; */
  --padding-md: 12px;
}

/*Media query 07-11-2023 start*/
@media only screen and (max-width: 767px) {
  .login-box-container {
    margin: 10px 0;
    width: 100%;
  }
}

/*Media query 07-11-2023 end*/