:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --midnightBlue: #002366;
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
  --red: #dd3139;
  --hoverGreen: #e7f3ec;
  --hoverRed: #fceaeb;
  --green: #0d873c;
  --veryDarkGrey: #636363;
  --grey: #767676;
}
.page-wrapper-attendee-details {
  position: relative;
  min-height: 100vh;
  content: "";
  background-color: #fbfbfb;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  background-position: bottom;
  overflow-x: hidden;
  overflow-y: hidden;
}

.attendee-details-header-sideNav {
  display: flex;
  flex-direction: row;
  width: 18%;
}

.attendee-details-content-div {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  /* margin-left: 350px; */
  padding-right: 16px;
  padding-bottom: 440px;
}

.attendee-details-parent-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-top: 48px;
  margin-bottom: 48px;
  margin-left: 48px;
  margin-right: 48px;
}

.attendee-details-avatar {
  height: 192px;
  width: 192px;
  border-radius: 96;
  background-color: var(--white);
  box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.07);
}

.attendee-details-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 48px;
  margin-top: 48;
}

.attendee-personal-details-parent-content {
  margin-left: 48px;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 32px;
  margin-right: 48px;
}

.page-wrapper-attendee-details .attendee-details-parent-content .proLeftBox {
  flex: auto !important;
}
.page-wrapper-attendee-details .attendee-details-parent-content .rightBtnCont {
  flex: 334px 0 0 !important;
}
.page-wrapper-attendee-details .attendee-details-parent-content .rightBtnCont .Box1 {
  width: auto!important;
  max-width: 100%;
}
.page-wrapper-attendee-details .attendee-details-parent-content .avatarBox {
  flex: auto 0 0!important;
  position: relative;
  padding:0 20px 0 0;
}
.page-wrapper-attendee-details .attendee-details-parent-content .avatarBox .attendee-details-avatar {
  aspect-ratio: 1/1;
  object-fit: cover;
  display: block;
  max-width: fit-content;
}
.page-wrapper-attendee-details .attendee-details-parent-content .avatarBox .statusIconCont {
  position: absolute;
  margin: 0 !important;
  right: 0;
  bottom: 0;
}
@media (min-width:768px) and (max-width: 1024px) {
  .page-wrapper-attendee-details .attendee-details-parent-content .proLeftBox {
    width: 100%;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .avatarBox .attendee-details-avatar {
    width: 130px;
    height: 130px;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .avatarBox .statusIconCont svg {
    height: 42px;
    width: 42px;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .rightCont {
    margin-left: 20px!important;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .rightBtnCont {
    width: 100%;
    flex-direction: row !important;
    flex: auto !important;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .rightBtnCont .Box1 {
    width: 50% !important;
    max-width: 50%;
  }
}

@media only screen and (max-width: 1199px) {
  .attendee-details-header-sideNav {
    width: 30%;
  }
}

@media only screen and (max-width: 767px) {
  .attendee-details-header-sideNav {
    width: 100%;
  }
  .page-wrapper-attendee-details .event-details-top-after-menulink-container .event-details-event-scroll-container {
    display: flex;
  }
  .page-wrapper-attendee-details .event-details-top-after-menulink-container .event-details-event-scroll-container .event-detailsTop-btns-inner {
    width: 100%;
  }
  .page-wrapper-attendee-details .event-details-top-after-menulink-container .event-details-event-scroll-container .event-detailsTop-btns-inner .btnBox .create-event-btnCustom {
    height: 40px;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content {
    margin: 40px 16px;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .proLeftBox {
    flex-direction: row !important;
    gap:15px;
    margin-top: 0!important;
    width: 100%;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .avatarBox {
    margin: 0 !important;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .avatarBox .attendee-details-avatar {
    height: 120px;
    width: 120px;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .avatarBox .statusIconCont svg {
    height: 35px;
    width: 35px;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .rightCont {
    margin: 0 !important;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .rightCont > span {
    font-size: 24px !important;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .rightCont .verifiedCont {
    flex-direction: row !important;
    margin-top: 16px !important;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .rightBtnCont {
    margin-top: 32px !important;
    gap: 10px;
    width: 100%;
    flex-direction: row !important;
    flex: auto !important;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .rightBtnCont .Box1 {
    margin: 0 !important;
    width: 50%;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .rightBtnCont #box1.Box1:first-child {
    margin-left: 0 !important;
  }
  .page-wrapper-attendee-details .attendee-details-scroll-container {
    margin: 0 16px !important;
    padding-bottom: 10px;
  }
  .page-wrapper-attendee-details .attendee-details-scroll-container .detailBtnBox .create-event-btnCustom {
    height: 40px !important;
    width: auto;
  }
  .page-wrapper-attendee-details .attendee-personal-details-parent-content {
    margin: 16px 16px 0 16px!important;
  }
  .page-wrapper-attendee-details .attendee-personal-details-parent-content > div > div > div > div:last-child {
    justify-content: center !important;
  }
  .page-wrapper-attendee-details .attendee-personal-details-parent-content > div > div > div > div:last-child span:first-child {
    font-size: 12px!important;
  }
  .page-wrapper-attendee-details .attendee-personal-details-parent-content > div > div > div > div:last-child span:last-child {
    font-size: 16px!important;
  }
}

@media only screen and (max-width: 479px) {
  .page-wrapper-attendee-details .attendee-details-parent-content .rightBtnCont {
    flex-direction: column !important;
  }
  .page-wrapper-attendee-details .attendee-details-parent-content .rightBtnCont .Box1 {
    width: 100% !important;
  }
}