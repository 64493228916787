:root {
    --white: #FFFFFF;
    --midBlue: #0057FF;
    --lightGrey: #EBEBEB;
    --veryLightGrey: #FBFBFB;
    --black: #000000;
    --darkBlue: #1458C2;
    --veryDarkBlue: #063279;
  }

  .searchInputField-searchContentView {
width: 100%;
border-radius: 8px;
border: 1px solid var(--lightGrey);
height: 48px;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
border-width: 1px;
}

.searchInputField-inputFieldPrimary{
    display: flex;
    text-align: left;
    width: 75%;
    height: 20px;
    color: var(--midnightBlue);
    border: 0 !important;
    border-width: 0px;
    box-shadow: inherit;
    background-color: transparent;    
    vertical-align: middle !important;
    outline: none;
    flex: 1;
    margin-right: 16px;
  }

  .searchInputField-clearSearchContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 16px;
    height: 20px;
    width: 20px;
    border-radius: 11px;
    align-items: center;
    z-index: 1;
  }

  .searchInputField-searchIcon {
    margin-left: 16px;
    margin-right: 12px;
    height: 20px;
    width: 20px;
  }
