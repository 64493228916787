.loader-container22 {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.loader-container22.show22 {
    display: block;
}

.central-modal-overlay22 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(13, 34, 68, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }