:root {
  --midBlue: #0057FF;
  --lightGrey: #EBEBEB;
  --darkBlue: #1458C2;
  --white: #FFFFFF;
  --veryDarkGrey: #636363;
  --red: #DD3139;
}
.Box1{
  display: flex;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
  /* height: 44px; */
  height: 48px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}