:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --midnightBlue: #002366;
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
  --red: #dd3139;
  --grey: #767676;
}
.summary-labeled-div {
  margin-top: 40px;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--white);
  border: 1px solid var(--lightGrey);
  box-shadow:0px 2px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 36px 24px;
}
.ticket-summary-payment-overview-container {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--lightGrey);
  background: var(--hoverFadeBlue);
  margin-top: 40px;
}
