:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --midnightBlue: #002366;
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
  --black: #000000;
  --grey: #767676;
}

.timing-about-div {
  /* margin-top: 40px; */
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--white);
  border: 1px solid var(--lightGrey);
  border-radius: 8px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0px 28px;
  margin-bottom: 40px;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: var(--lightGrey) !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  margin-top: 5px;
  background: url("../../../assets/svgs/ChevronDown.svg") no-repeat;
}

.TimingBodyCont {}

.TimingBodyCont .TimingStartEndDate {}

.date-parent-box .date-child-box {
display: flex;
            flex-direction: column;
            flex: 1;
          

}

.TimingBodyCont .TimingStartEndDate .EndDateBox {
  margin-top: 32px;
  display: flex;
            flex-direction: column;
            flex: 1 / 2;
            padding-right: 64;
}

.date-parent-box .date-child-box .BoxLabel {
  /* margin-bottom: 12px; */
  margin-bottom: 8px;
}

.TimingBodyCont .TimingFromToTime {}

.TimingBodyCont .TimingFromToTime .TimingFromBox {}

.TimingBodyCont .TimingFromToTime .TimingLastBox {
  margin-top: 32px;
}

.TimingBodyCont .TimingFromToTime .BoxLabel {
  /* margin-bottom: 12px; */
  margin-bottom: 8px;
}

@media screen and (max-width:767px) {
  .page-wrapper-event .rht-body-inner-content .CreateEventBody .timing-about-div .generic-common-row-div {
    display: flex !important;
    align-items: center !important;
    justify-content: start;
  }

  .page-wrapper-event .rht-body-inner-content .CreateEventBody .timing-about-div .date-parent-box {
    display: flex !important;
    align-items: center !important;
    justify-content: start;
    flex-direction: column !important;
    margin-top: 16px !important;
  }

  .page-wrapper-event .rht-body-inner-content .CreateEventBody .timing-about-div .date-parent-box:last-child {
    margin-top: 0 !important;
  }


  .page-wrapper-event .rht-body-inner-content .CreateEventBody .timing-about-div .date-parent-box .date-child-box {
    display: flex !important;
    width: 100% !important;
    margin-top: 28px !important;
  }

  .page-wrapper-event .rht-body-inner-content .CreateEventBody .timing-about-div .generic-common-row-div .TimingsInfoIconCont {
    display: inline-block !important;
  }

  .page-wrapper-event .rht-body-inner-content .TimingBodyCont .TimingStartEndDate,
  .page-wrapper-event .rht-body-inner-content .TimingBodyCont .TimingFromToTime {
    display: flex !important;
  }

  .page-wrapper-event .rht-body-inner-content .TimingBodyCont .TimingFromToTime {
    margin-left: 0 !important;
  }
}