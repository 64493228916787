:root {
  --lightGrey: #ebebeb;
  --iceWhite: #fff;
  --red: #dd3139;
  --green: #0d873c;
  --grey: #767676;
  --midBlue: #0057FF;
}
.code-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
}
.input-code-field-container {
  /* border: 0 !important;
  border-width: 0px; */
  /* box-shadow: none !important;
  outline: none !important;
  border-style: none; */
  border-width: 1.5px;
  border-color: red;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.03);
  background-color: white !important;
  height: 56px;
  width: 64px;
  padding: 0px;
  border-radius: 4px;
  /* padding-left: 8px;
  padding-right: 8px; */
  /* padding-top: 4px; */
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  outline: none !important;
}

.input-code-field,
.inputFieldPrimary:focus-visible {
  outline: none !important;
}

#codeField {
  font-weight: 400;
  font-size: 40px;
  line-height: 1.3;
}


@media(max-width:575px){

  .input-code-field-container{width:45px; height: 45px; margin:0 4px;}
  .input-code-field-container .input-code-field{font-size:16px;}

}
