:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --midnightBlue: #002366;
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
  --red: #dd3139;
  --hoverGreen: #e7f3ec;
  --hoverRed: #fceaeb;
  --green: #0d873c;
  --veryDarkGrey: #636363;
  --grey: #767676;
}
.page-wrapper-attendee {
  position: relative;
  min-height: 100vh;
  content: "";
  background-color: #fbfbfb;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  background-position: bottom;
  overflow-x: hidden;
  overflow-y: hidden;
}

.scan-progress-indicator-content-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  border-bottom-color: "var(--lightGrey)";
  border-bottom-width: 1px;
}

/* .attendee-header-sideNav {
  display: flex;
  flex-direction: row;
  width: 18%;
} */

.attendee-content-div {
  display: flex;
  flex-direction: column;
  /* margin-top: 24px; */
  /* margin-left: 350px;
  padding-right: 16px; */
  padding-bottom: 48px;
}

.attendee-parent-content {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 40px;
  margin-bottom: 32px;
  margin-left: 48px;
  margin-right: 24px;
  gap: 48px;
}

.attendee-event-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  height:72px;
}

.attendee-list-container {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.attendee-list-parent-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 36px;
  /* margin-bottom: 64px; */
  margin-left: 24px;
  margin-right: 24px;
  /* height: 558px;  */
  background-color: var(--white);
  padding-top: 8px;
  padding-bottom: 0px;
  border-radius: 8px;
  border-color: var(--lightGrey) !important;
  border-width: 1px !important;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
  position: relative;
}

/* css start 12-01-24 */

.avhguyf {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100%;
}

.attendeeList-nav {
  background: #fff;
}

/* css end 12-01-24 */


.titleCont span {
  display: block;
}
.subTitleCont span {
  display: block;
}

.event-details-top-innerMenu .event-details-topMneu-rht {
  height: auto!important;
}

.invoice-history-listing-container .avhguyf.event-details-top-innerMenu{background-color:transparent !important;}

.event-details-top-innerMenu .generic-common-row-div .Box1 {
  min-width: 150px;
  width: auto !important;
}

.event-details-top-innerMenu .generic-common-row-div{justify-content:flex-end;}


@media only screen and (max-width:1024px) {

    .attendee-event-bar .dropdown{padding:0 0 0 12px;}


}

@media only screen and (max-width: 767px) {
  /* .attendee-header-sideNav {
    width: 100%;
  } */
  .page-wrapper-attendee .attendee-parent-content {
    margin-top: 32px !important;
    margin-bottom: 48px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .page-wrapper-attendee .attendee-parent-content .content-top > div {
    margin-top: 0;
  }
  .page-wrapper-attendee .attendee-parent-content .content-top .subTitleCont span {
    font-size: 16px!important;
  }
  .page-wrapper-attendee .attendee-parent-content .content-top .tags-inner-container.create-event-btnCustom {
    margin-top: 0;
    width: 75px !important;
    height: 36px;
    border: none !important;
  }
  .page-wrapper-attendee .attendee-parent-content .content-top .tags-inner-container.create-event-btnCustom svg {}
  .page-wrapper-attendee .attendee-parent-content .content-top .tags-inner-container.create-event-btnCustom span {
    font-size: 14px !important;
    margin: 0 0 0 10px !important;
  }
  .page-wrapper-attendee .attendee-parent-content .content-bottom  {
    justify-content: space-between!important;
    margin-top:0px;
    flex-wrap: wrap;
    height:auto !important;
  }
  .page-wrapper-attendee .attendee-parent-content .content-bottom .countBox {
    justify-content: center !important;
    margin: 0 !important;
    align-items: center;
    flex: 0 0 50% !important;
    padding:0 0 25px !important;
  }
  .attendee-event-bar {
    flex-direction: column !important;
    justify-content: start !important;
    gap: 10px;
    margin-left: 16px !important;
    margin-right: 16px !important;
    margin-top: 16px !important;
  }
  .attendee-event-bar .attendee-event-scroll-container {
    flex-direction: row;
    overflow: auto;
    padding-bottom: 10px;
  }
  .attendee-event-bar .attendee-event-scroll-container .tags-inner-container.create-event-btnCustom {
    height: auto;
    width: auto;
  }
  .attendee-event-bar .attendee-event-scroll-container .tags-inner-container.create-event-btnCustom span {
    font-size: 16px !important;
    line-height: 1.4em !important;
    text-wrap: nowrap!important;
  }
  .attendee-event-bar > div > .dropdown .dropdown-toggle {
    height: auto !important;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .titleCont span {
    margin-top: 16px!important;
  }
  .subTitleCont span {
    margin-top: 16px!important;
  }
  .page-wrapper-attendee .attendee-search-cont {
    width: 100%!important;
    margin: 0 0 16px 0!important;
    padding: 0 16px;
  }
  .page-wrapper-attendee .attendee-list-parent-content {
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .attendee-event-bar .dropdown{padding:0 0 0 0px;}
   
    .attendee-event-scroll-container {
      height: auto;
      width: 100% !important;
      max-width: 100% !important;
      margin:0 !important;
   }

}
