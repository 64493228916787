:root {
  --white: #ffffff;
  --blue: #0f5bcd;
  --green: #0d873c;
  --yellow: #ffb800;
  --red: #dd3139;
  --black: #000000;
}

.top-banner-container1 {
  position: absolute;
  left: 0;
  right: 0;
  width: 93%;
  margin: 0 auto;
  z-index: 1;
  /* top: 16px; */
}

.top-banner-modal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 4px;
  align-items: flex-start;
  padding: 16px;
  position: fixed;
}
.page-wrapper-su .banner-outside-container
{
  padding-left: 8px !important;
  top: 8px;
}

  .banner-outside-container{    padding-left:364px; width: 100% !important; left:0 !important; right: inherit !important; position:fixed !important;}
  .page-wrapper-login .banner-outside-container{padding-left:11px !important;}
  /* .banner-outside-container .top-banner-modal{position: relative !important;     width: 99.5% !important; left: 0 !important;} */
  .banner-outside-container .top-banner-modal{position: relative !important; left: 0 !important;}

  .banner-outside-container .top-banner-modal div span{width:auto !important;}

  .banner-outside-container.banner-outside-container-bottom{top:initial !important; bottom:6px !important;}

  .banner-outside-container.banner-outside-container-bottom-width{top: initial !important;  bottom: 6px !important; width: auto !important;
      left: 50% !important;
      right: initial !important;
      transform: translate(-50%, 0%);
  }

  .banner-outside-container.banner-outside-container-top-width{
    top:6px !important;  bottom:initial !important; width: auto !important;
      left: 50% !important;
      right: initial !important;
      transform: translate(-50%, 0%);
  }


@media(max-width:767px) {

  .banner-outside-container{padding-left:0px !important; top:55px !important;}


}