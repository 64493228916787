:root {
  --white: #ffffff;
  --midGrey: #bebebe;
  --green: #0d873c;
  --lightGrey: #ebebeb;
}

.option-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  border: 1px solid var(--lightGrey);
  margin-bottom: 24px;
  border-radius: 8px;
  width: 100%;
}
