:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --midnightBlue: #002366;
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
  --red: #dd3139;
  --grey: #767676;
}

.event-about-div {
  /* margin-top: 32px; */
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--white);
  border: 1px solid var(--lightGrey);
  border-radius: 8px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 40px 28px;
}

.venue-list-item-parent-content {
  overflow-y: auto;
  margin-right: 0px;
  position: absolute !important;
  z-index: 2;
  max-height: 217px !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  /* margin-bottom: 64px; */
  /* margin-left: 24px;
  margin-right: 24px; */
  margin-left: 0px;
  margin-right: 0px;
  /* height: 558px;  */
  background-color: var(--white);
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  border-color: var(--lightGrey) !important;
  border-width: 1px !important;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  padding: 4px 0px;
}

.venue-list-line-item-container .venue-list-line-item-inner {
  padding: 0 10px;
}

.venueList-inner-widthScroll:last-child .venue-list-line-item-container {
  border-bottom: none;
}

.venue-list-line-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0px;
  padding-right: 16px;
  border-bottom: 1px solid #e7e7e7;
}

.createEvnSD-btn-area {
  display: flex;
  padding: 0px 30px 0;
  flex-wrap: wrap;
  row-gap: 17px;
  margin: 0 auto !important;
}

.createEvnSD-btn-area .Box1 {
  transition: all 0.85s ease;
  cursor: pointer;
}

.createEvnSD-btn-area .Box1 span {
  cursor: pointer !important;
}

/* .createEvnSD-btn-area .Box1:first-child {
  background-color: #ebebeb !important;
  border: 1px solid #ebebeb !important;
}
/* .createEvnSD-btn-area .Box1:first-child:hover{background-color:transparent !important;} */
/* .createEvnSD-btn-area .Box1:first-child span {
  color: #636363 !important;
}
*/

/* .createEvnSD-btn-area .Box1:last-child:hover{background-color:rgb(221, 49, 57) !important;} */

.add-entrance-btn {
  padding: 0px 30px 30px;
}

.event-description-input-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--lightGrey);
  border-radius: 4px;
  height: 124px;
  align-items: "center";
  width: 100%;
}

.event-description-top-aligned-input {
  font-family: "Poppins Regular 400", sans-serif;
  font-size: 16px;
  color: var(--midnightBlue);
  display: flex;
  flex: 1;
  border: none;
  outline: none;
  border-radius: 4px;
  vertical-align: top;
}

.banner-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--lightGrey);
  border-radius: 4px;
  align-items: "center";
  background-color: var(--veryLightGrey);
  padding-top: 25px;
  padding-bottom: 25px;
  align-items: center;
}

.banner-container-img {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--lightGrey);
  border-radius: 4px;
  align-items: "center";
  background-color: var(--veryLightGrey);
  align-items: center;
}

.file-drop .banner-upload-block .ClickToUploadCont {
  margin-top: 18px !important;
}

.file-drop .banner-upload-block .ImgMaxReq {
  margin: 10px 0 0;
}

.ClickToUploadCont {
  text-align: center;
  flex-wrap: wrap;
}

.ImgMaxReq {
  text-align: center;
}

.CreateEventMapCont .map-container {
  height: 282px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .event-about-div .EventDetailsTopTitle .generic-common-row-div {
    display: flex !important;
    align-items: unset;
  }

  .event-about-div .EventDetailsTopTitle .generic-common-row-div>span {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .venue-list-line-item-container {
    /* width: 680px; */
    padding: 8px 0px;
  }

  .file-drop .file-drop-target .banner-container.banner-upload-block {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }

  .banner-container.banner-upload-block .BusinessSignUp-inner-createBlock>div {
    height: 100%;
  }

  .banner-container.banner-upload-block .BusinessSignUp-inner-createBlock>div>div>svg {
    height: 100%;
    width: 100%;
  }

  .banner-container.banner-upload-block .BusinessSignUp-inner-createBlock>div>div>div {
    margin-top: 0 !important;
    top: 0;
  }

  .event-about-div .abcd-div .banner-container-img>div {
    top: 0;
    right: 0 !important;
  }

  .banner-container.banner-upload-block .ClickToUploadCont span,
  .banner-container.banner-upload-block .ImgMaxReq span {
    position: unset;
  }

  .page-wrapper-event .event-about-div .generic-common-row-div {
    /* display: flex !important;
    justify-content: start; */
  }

  .event-about-div .EventDetailsTopTitle .generic-common-row-div>span {
    margin-bottom: 0 !important;
  }

  .event-about-div .EventDetailsTopTitle .generic-common-row-div>div {
    width: auto !important;
  }

  .file-drop .banner-upload-block .ImgMaxReq {
    margin: 4px 0 0;
  }

  .event-about-div {
    box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.04), 0px 7.704px 25.481px 0px rgba(0, 0, 0, 0.02), 0px 1.63px 6.519px 0px rgba(0, 0, 0, 0.02);
  }

}


@media screen and (max-width:575px) {

  .popup-content {
    width: 92% !important;
    left: 6% !important;
    margin: 6px 0px !important;
  }


}