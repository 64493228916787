:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --midnightBlue: #002366;
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
}

.custom-card-element {
  font-weight: 500;
  border-radius: 2px;
  --stripe-color-primary: #f360a6;
  --stripe-tab-icon-selected-color: #fff;
  --stripe-grid-row-spacing: 16px;
}

.add-payment-button{
  background-color: var(--midBlue);
  color: #fff;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.crop-banner-for-square .reactEasyCrop_Container {
  background-color: #f1f1f1;
  width: 393.75px;
  margin: 0 auto;
}

.Tab, .Input, .Block, .CheckboxInput, .CodeInput {
  box-shadow: 0px 3px 10px rgba(18, 42, 66, 0.08);
}

.Block {
  border-color: transparent;
}

.BlockDivider {
  background-color: #ebebeb;
}

.Tab, .Tab:hover, .Tab:focus {
  border: 0;
}

.Tab--selected, .Tab--selected:hover {
  background-color: #f360a6;
  color: #fff;
}

.containerInputCardNumber {
  display: flex;
  flex-direction: row;
  border-width: 1px;
  border-color: red;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 1px;
  height: 48px;
  align-items: center;
  border-style: solid;
}

.page-wrapper-home {
  position: relative;
  min-height: 100vh;
  content: "";
  background-color: #fbfbfb;
  width: 100%;
  /* height: 76%; */
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  /* z-index: -1; */
  background-position: bottom;
  overflow-x: hidden;
  overflow-y: hidden;
}

.page-wrapper-home .header {
  z-index: 99999;
}

.amount-pay-container {
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--lightGrey);
  background: var(--hoverFadeBlue);
  width: 100%;
  /* margin-top: 16px; */
}

.account-not-verify-container {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--lightGrey);
  background: var(--hoverFadeBlue);
  margin-top: 40px;
}

.payment-connect-not-added-card-container {
  padding: 24px;
  border-radius: 10px;
  background-color: "var(--veryLightGrey)";
  border-width: 1px;
  text-align: center;
}

/* .home-header-sideNav {
  display: flex;
  flex-direction: row;
  width: 18%;
  z-index: 1;
} */
.home-content-div {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  /* margin-left: 384px; */
  padding-right: 16px;
}

.home-info-row-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.home-scroll-container {
  margin-top: 16px;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home-card-container {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  row-gap: 40px;
  height: auto;
  /* padding-bottom: 280px; */
  padding-bottom: 32px;
  border-radius: 18px;
}

.home-accordion-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

/* .Header_nav__S0AJ5 {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
} */
.mainTop-header-bar {
  border-bottom: 1px solid #EBEBEB;
}

/* css start 12-01-24 */
.main-body-content {
  display: flex;
}

/* .rht-body-inner-content {
  width: 82%;
  padding-left: 24px;
} */
/* css end 12-01-24 */
.noData-parent-container {
  flex-wrap: wrap;
  max-width: 640px;
  padding: 0 12px;
  margin: 0 auto;
}

.noData-parent-container span {
  text-wrap: inherit !important;
  width: auto !important;
}

.home-content-div .home-info-row-div {
  gap: 0 5px;
}

.card-block {
  background-color: transparent;
  border: none;
}

.listingTag div {
  /* background-color:#EBEBEB !important; */
}

.listingTag span {
  /* color:#767676 !important; */
}

.card-block .inner-tag .details-tag-live path {
  fill: #fff;
}

.card-block .inner-tag .details-tag-live .tags-inner-container span {
  color: #fff !important;
}

.topCreate-eventHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.eventItem-description-box {
  width: 100%;
}

/* css start 14-0824 */

.email-textWrap span{display:block; word-break:break-all;}

/* css end 14-0824 */


@media(max-width:991px) {

  .generic-common-row-div-space-between-not-centered.SettingPaymentTopCont .CommonSpaceBetweenRow{flex-wrap:wrap;}
  .SettingCommonBoxCont .SettingPaymentBodyCont{flex-wrap:wrap;}
  .SettingCommonBoxCont .SettingPaymentBodyCont .generic-common-column-div-half-width{width:100%;}

  .payment-items-box-inner{grid-template-columns: repeat(1, 1fr) !important;}
  .payment-verified-items .payment-verified-inner{flex-wrap:wrap;}
  .payment-verified-items .payment-verified-inner .payment-verified-inner-lft{width:100% !important;}
  .payment-verified-items .payment-verified-inner .Box1{width:100% !important; margin:25px 0 0;}

  .SettingPaymentTopCont .CommonSpaceBetweenRow{flex-direction:column;}
  .SettingPaymentTopCont .CommonSpaceBetweenRow .CommonSpaceBetweenCol .BtnCont296{margin:0;}
 

}

@media(max-width:767px) {
  .topCreate-eventHeader {
    /* align-items:center;
    padding:24px 24px; */
  }

  .topCreate-eventHeader div#box1 {
    margin: 0px;
  }

  .topCreate-eventHeader .Box1 {
    width: auto !important;
    height: auto;
    padding: 12px 16px;
  }

  .topCreate-eventHeader div#box1 span {
    font-size: 16px !important;
  }

  .topCreate-eventHeader .Box1 svg {
    width: 16px !important;
    height: 16px !important;
  }

  .homeContent-inner-btns {
    padding-left: 0px !important;
  }

  .home-info-row-div.homeTob-btns-tool {
    display: flex;
    flex-wrap: wrap;
  }

  .home-scroll-container.homeTob-btns-tool-lft {
    width: 100%;
    order: 2;
    display: flex;
    margin: 0px;
    /* flex-wrap:wrap; */
    gap: 0 8px;
    /* padding:0px !important; */
  }

  .home-scroll-container.homeTob-btns-tool-rht {
    width: 100%;
    order: 1;
    margin: 0 !important;
    display: flex;
    /* flex-wrap:wrap; */
    gap: 0 8px;
    /* padding:0px !important; */
  }

  .homeTob-btns-tool .home-scroll-container .create-event-btnCustom {
    /* padding: 8px 12px !important;
    height: auto; */
  }

  .home-info-row-div.homeTob-btns-tool {
    /* padding:12px 0 0; */
  }

  .generic-common-column-div.eventItem-description-box,
  .generic-common-row-div.eventItem-description-top {
    justify-content: flex-start;
  }

  .generic-common-row-div.eventItem-description-top .listingTag {
    /* margin-left:0px !important;
        margin-top:8px; */
  }

  .home-card-container .card-body .card-title,
  .home-card-container .card-body .card-title span {
    text-align: left !important;
  }

  .home-card-container .card-body .card-title {
    margin-bottom: 0px;
  }

  .home-card-container .card-body {
    height: auto;
  }

  .generic-common-column-div.eventItem-description-box {
    margin-top: 12px !important;
  }

  .home-card-container .card-block .card-body {
    padding-bottom: 15px !important;
  }

  .mobile-menu-content .sb-options-div {
    /* text-align: center; */
  }

    .setting-custom-container{padding-left:0px !important; border-radius:0px;}
    .setting-custom-container .setting-custom-inner{padding-left:0px !important;}
    .setting-custom-container .account-verified-container{border-radius:0px !important;}

    .account-not-verify-block{padding-left:16px; padding-right:16px;}
    .setting-custom-container .allla.SettingCommonBoxCont{border-radius:0px !important; box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08);}

    .payment-connect-not-added-card-container span{text-align:center !important;}
    .setting-tittle-container {
      padding: 0 24px;
    }

    .payment-verified-container{border-radius:0px !important;}
    .payment-items-box-inner .payment-verified-container{border-radius:6px !important; margin-top:25px;}

    .invoice-history-listing-container{padding:0 24px;}
    .invoice-history-listing-container .order-list-item-parent-content{margin-left:0px; margin-right:0px;}
    .invoice-history-listing-container .event-details-top-innerMenu{background-color:transparent !important;}

}