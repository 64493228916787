:root {
  --white: #ffffff;
  --midBlue: #0057FF;
  --midnightBlue: #002366;
  --lightGrey: #ebebeb;
  --veryLightGrey: #fbfbfb;
  --midnightBlue: #002366;
  --green: #0d873c;
  --red: #dd3139;
  --hoverFadeBlue: #eaf1fc;
}

.event-details-pie-chart-parent-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--white);
  padding-top: 40px;
  padding-left: 24px;
  padding-right: 32px;
  padding-bottom: 40px;
  border-radius: 8px;
  border-color: var(--lightGrey) !important;
  border-width: 1px !important;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
  position: relative;
}
.event-details-centered-div-into-pie-chart {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-54%, -50%);
  width: 270px;
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: blanchedalmond; */
}

.event-details-centered-div-into-pie-chart-right {
  position: absolute;
  right: 172px;
  top: 50%;
  left: 50%;
  transform: translate(-53%, -51%);
  width: 270px;
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.live-event-statistics-right-parent-container {
  display: flex !important;
}

.sales-info-inner-parent-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  background-color: var(--hoverFadeBlue);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 31px;
  padding-bottom: 31px;
  border-radius: 8px;
  /* width: 204px; */
  width: 50%;
  height: 100%;
}

/* .event-details-pie-chart-parent-content div div{position: relative;}
.event-details-pie-chart-parent-content div .event-details-centered-div-into-pie-chart-right{position:absolute;} */

.eventDetails-slick-container .slick-slide {
  overflow: hidden;
}

/* css start 28-06-24  */

  .generic-common-row-div.saleStatics-tittle-block{width:100%; column-gap:11px;}
  .generic-common-row-div.saleStatics-tittle-block .DropdownCont{margin-left:auto;}

  .live-event-statistics-left-parent-container .live-event-statistics-inner-label-container{padding:0 11px 0 0;}

/* css end 28-06-24  */


@media (max-width:999px) {

  .generic-common-row-div.saleStatics-tittle-block{flex-wrap:wrap; row-gap:8px;}
  .generic-common-row-div.saleStatics-tittle-block .saleStatics-text{order:1;}
  .generic-common-row-div.saleStatics-tittle-block .ticket-sales-batch{order:3; margin:0;}
  .generic-common-row-div.saleStatics-tittle-block .DropdownCont{order:2; margin:0;}

}


@media (max-width: 767px) {
  .statistics-inner-itemBox {
    width: 32% !important;
    padding: 0 10px;
  }
  .statistics-smText span {
    font-size: 12px !important;
  }

  .live-event-statistics-inner-label-container {
    flex-direction: row !important;
    width: 100% !important;
    align-items: flex-end;
  }

  .slick-dots li button:before {
    display: none;
  }
  .slick-dots li {
    width: 4px !important;
    height: 4px !important;
    border-radius: 50%;
    background-color: #bebebe;
  }
  .slick-dots li.slick-active {
    width: 16px !important;
    height: 4px !important;
    border-radius: 25px;
    background-color: #0057FF;
  }
  .statistics-inner-itemBox {
    width: 32% !important;
  }

  .eventDetails-slick-container .sales-info-inner-parent-div {
    width: 50%;
  }

  .statistics-smText {
    height: 30px;
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .page-wrapper-event-details .rht-body-inner-content .slick-slide .generic-common-row-div .sales-info-inner-parent-div {
    min-height: 140px;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .page-wrapper-event-details .rht-body-inner-content .slick-slide .generic-common-row-div .sales-info-inner-parent-div {
    /* min-height: 140px; */
  }
  .eventDetails-slick-container .live-event-statistics-left-parent-container {
    padding-right: 0;
    margin-bottom: 30px;
  }
  .eventDetails-slick-container .slick-slide {
    width: 100% !important;
  }
}
@media (min-width:768px) and (max-width:1249px) {
  .sales-info-inner-parent-div {
    width: 100%;
  }
}
@media (min-width:1250px) and (max-width:1300px) {
  .sales-info-inner-parent-div {
    height: 140px;
  }
}